import {FC, Fragment, ReactNode} from "react";
import {Sidebar} from "./menu/Sidebar";
import {Outlet} from "react-router-dom";
import Navbar from "./menu/Navbar";

const AppLayout:FC<any> = ({children})=> {
    return (
        <Fragment>
            <div className="d-flex flex-row" >
                    <Sidebar/>
                <div className="d-flex flex-column d-block w-100 container position-absolute " style={{
                    right: "0"
                }}>
                       <Navbar/>
                    <div className=" py-3 px-3 d-flex flex-column ">
                        {children}
                    </div>
                </div>
            </div>

        </Fragment>
    )
}

export {AppLayout}