import { Fragment, useEffect, useState } from "react";
import { axiosGet } from "../../../../modules/helpers/RequestHelper";
import { Button, Nav } from "react-bootstrap";
import { Table } from "../../../../modules/widgets/table/Table";
import { PropertyAmenityColumn } from "../../../../modules/widgets/table/core/Columns";
import { CreateAmenityModal } from "../../../../modules/widgets/modals/amenity/CreateAmenityModal";

const AmenityList = () => {
  const [data, setData] = useState<any[]>([]);
  const [openCreateModal, setOpenCreateModal] = useState(false);

  useEffect(() => {
    (async () => {
      const req = await axiosGet("/property/amenity/list");
      if (req?.success) {
        const { amenity } = req;
        setData(amenity);
      }
    })();
  }, []);
  return (
    <Fragment>
      <CreateAmenityModal
        show={openCreateModal}
        onHide={() => setOpenCreateModal(false)}
      />
      <div className="d-flex justify-content-end my-3 gap-3">
        <Button onClick={() => setOpenCreateModal(true)} variant="primary">
          Create Amenity
        </Button>
      </div>

      <Table columns={PropertyAmenityColumn} data={data} />
    </Fragment>
  );
};

export { AmenityList };
