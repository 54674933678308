import { FC, Fragment, useEffect, useState } from "react";
import DataTable, { createTheme } from "react-data-table-component";
import { Card } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { SectionLoader } from "../../helpers/Utils";

type tableProps = {
  data: any[];
  columns: any[];
  allowClick?: boolean;
};

const Table: FC<tableProps> = ({ data, columns, allowClick = false }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [pending, setPending] = useState(true);
  const [column, setColumn] = useState<any[]>([]);
  const [isData, setIsData] = useState(data);
  useEffect(() => {
    setIsData(data);
    const timeout = setTimeout(() => {
      setColumn(columns);
      setPending(false);
    }, 2000);
    return () => clearTimeout(timeout);
  }, [columns, data]);
  const customStyles = {
    table: {
      style: {},
    },
    tableWrapper: {
      style: {
        display: "",
      },
    },
    responsiveWrapper: {
      style: {
        overflow: "visible",
        borderRadius: "40px",
      },
    },
    rows: {
      style: {
        minHeight: "72px", // override the row height
      },
    },
    // headCells: {
    //     style: {
    //         paddingLeft: '8px', // override the cell padding for head cells
    //         paddingRight: '8px',
    //         fontSize: '1.2em',
    //         textAlign: 'center',
    //         padding: "20px"
    //         // display: `flex`,
    //         // justifyContent: `center`,
    //     },
    // },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
        textTransform: "capitalize",
        textAlign: "center",
        padding: "20px",
        // display: `flex`,
        // justifyContent: `start`,
      },
    },
  };
  createTheme(
    "solarized",
    {
      text: {
        primary: "#111827",
        secondary: "#6B7280",
        fontFamily: "Poppins, sans-serif",
        fontSize: "12px",
        fontWeight: "400",
        hover: "#FF9900",
      },
      background: {
        default: "#ffffff",
        hover: "#FF9900",
      },
      context: {
        background: "#BD9241",
        text: "#FFFFFF",
      },
      divider: {
        default: "#E5E7EB",
      },
      action: {
        button: "rgba(0,0,0,.54)",
        hover: "#FF9900",
        disabled: "rgba(0,0,0,.12)",
      },
    },
    "light",
  );
  const handleClick = (e: any) => {
    const currentLocation = pathname.split(`/`).reverse()[0];
    console.log(e, currentLocation);
    navigate(`/control/${currentLocation}/${e.loginId}`);
  };
  return (
    <Fragment>
      <Card border="light" className="">
        <Card.Body>
          <DataTable
            columns={columns}
            data={data}
            theme="solarized"
            pagination
            onRowClicked={(e) => {
              if (allowClick) handleClick(e);
            }}
            responsive={true}
            onSelectedRowsChange={handleClick}
            progressPending={pending}
            highlightOnHover
            pointerOnHover
            progressComponent={<SectionLoader />}
          />
        </Card.Body>
      </Card>
    </Fragment>
  );
};
export { Table };
