import React, { Fragment } from "react";
import { Col, Nav } from "react-bootstrap";
import { PageTabItem } from "../../../../modules/layout/menu/PageTabItem";
import { useParams } from "react-router-dom";

const ViewNav = () => {
  const { id } = useParams();
  return (
    <Fragment>
      <Col md={12} lg={12}>
        <Nav
          variant="pills"
          className="py-3 w-100 nowrap"
          defaultActiveKey={`/listing/view/${id}`}
        >
          <PageTabItem to={`/listing/view/${id}`} title="Overview" />
          <PageTabItem to={`/listing/view/calendar/${id}`} title="Calendar" />
          <PageTabItem
            to={`/listing/view/reservations/${id}`}
            title="Reservations"
          />
          <PageTabItem to={`/listing/view/reviews/${id}`} title="Reviews" />
          <PageTabItem to={`/listing/view/coupons/${id}`} title="Coupons" />
          <PageTabItem to={`/listing/view/manage/${id}`} title="Manage" />
        </Nav>
      </Col>
    </Fragment>
  );
};

export { ViewNav };
