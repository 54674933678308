import React, { FC, Fragment, ReactNode, useEffect } from "react";
import clsx from "clsx";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faArrowDown,
  faCloudUploadAlt,
} from "@fortawesome/free-solid-svg-icons";

type Props = {
  to: string;
  title: string;
  icon?: any;
  fontIcon?: string;
  hasBullet?: boolean;
  action?: any;
  access?: boolean;
  childrenId?: string;
  iconType?: string;
};

export function getCurrentUrl(pathname: string) {
  return pathname.split("/")[1];
}
export function checkIsActive(pathname: string, url: string) {
  const current = getCurrentUrl(pathname);

  const to = url.split("/")[1];
  if (!current || !to) {
    return false;
  }

  if (current === to) {
    return true;
  }

  return current.indexOf(to) > -1;
}
export type WithChildren = {
  children?: ReactNode;
};
export function checkActiveSub(pathname: string, id: string) {
  return pathname === id;
}

const SideMenuItem: FC<Props & WithChildren> = ({
  children,
  childrenId,
  to,
  title,
  icon,
  iconType,
  fontIcon,
  hasBullet = false,
  action,
  access = true,
}) => {
  const { pathname } = useLocation();
  const isActive = checkIsActive(pathname, to);

  return (
    <Fragment>
      <li className="nav-item w-100">
        <Link
          to={to}
          onClick={action}
          className={clsx("  rounded-0 p-3 d-flex align-items-center ", {
            "hm-link-active": isActive,
            "d-none": !access,
            collapsed: hasBullet,
          })}
          aria-current="page"
          data-bs-toggle={hasBullet && "collapse"}
          data-bs-target={hasBullet && `#${childrenId}`}
          aria-expanded={hasBullet && "false"}
        >
          {icon && (
            <FontAwesomeIcon
              icon={icon}
              className="me-2"
              style={{
                fontSize: "22px",
              }}
            />
          )}
          <span>{title}</span>
        </Link>
      </li>
    </Fragment>
  );
};

export { SideMenuItem };
