import React, { useState, useEffect, Fragment, FC } from "react";
import { useParams, useNavigate } from "react-router-dom";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import axios from "axios";
import {
  Container,
  Form,
  InputGroup,
  Button,
  Alert,
  Row,
  Col,
} from "react-bootstrap";
import { axiosGet, axiosPost } from "../../modules/helpers/RequestHelper";
import { LoaderHandler, SectionLoader } from "../../modules/helpers/Utils";
import { Simulate } from "react-dom/test-utils";
import load = Simulate.load;

const CalendarComponent: FC<{ reload?: any }> = ({ reload }) => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const [data, setData] = useState({
    id,
    events: [],
  });

  const [partial, setPartial] = useState({
    loading: true,
    error: false,
    errorMessage: "Error occurred.",
    reload: false,
  });

  const fetchEvents = async () => {
    try {
      const url = data.id ? `/property/list/${data.id}` : `/reservation/list/`;
      const response = await axiosGet(url); // Adjust axios call if needed

      if (response?.success) {
        const { reservation } = response.property;
        console.log(reservation);
        const events = reservation
          .map(({ log, id, code }: any) => {
            if (log?.confirmed) {
              return {
                id,
                code,
                title: `${response?.property?.internal_name} Reservation`,
                allDay: true,
                start: new Date(log.checkin),
                end: new Date(log.checkout),
                backgroundColor: "var(--hm-primary-green)",
              };
            }
            return null;
          })
          .filter((event: any) => event !== null); // Filter out null values

        setData((prevState) => ({ ...prevState, events }));
      } else {
        setPartial((prev) => ({
          ...prev,
          error: true,
          errorMessage: response?.message || "Failed to fetch events.",
        }));
      }
    } catch (error: any) {
      setPartial((prev) => ({
        ...prev,
        error: true,
        errorMessage: `Error syncing calendar: ${error.message}`,
      }));
    } finally {
      setPartial((prev) => ({
        ...prev,
        loading: false,
      }));
    }
  };

  const handleClickEvent = (clickInfo: any) => {
    const { event } = clickInfo;
    navigate(`/reservation/view/${event.extendedProps.code}`);
  };

  useEffect(() => {
    if (reload) {
      fetchEvents();
    } else {
      fetchEvents();
    }
  }, [data.id, reload]); // Re-fetch events when `id` changes

  console.log(data);
  return (
    <Fragment>
      <LoaderHandler
        loading={partial.loading}
        error={partial.error}
        errorMessage={partial.errorMessage}
      >
        <FullCalendar
          plugins={[
            dayGridPlugin,
            timeGridPlugin,
            listPlugin,
            interactionPlugin,
          ]}
          initialView="dayGridMonth"
          headerToolbar={{
            left: "prev,next",
            center: "title",
            right: "dayGridMonth,timeGridWeek,listWeek",
          }}
          events={data.events}
          selectable
          eventClick={handleClickEvent}
        />
      </LoaderHandler>
    </Fragment>
  );
};

export default CalendarComponent;
