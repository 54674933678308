import {Fragment} from "react";
import {useLocation} from "react-router-dom";

const PageTitle = ()=> {
    const {pathname} = useLocation()
    const path = pathname.split("/")[1]
    const current = pathname.split("/").reverse()[0].replace("-"," ")
    const title = `${path} ${current}`

    return (
        <Fragment>
            <h4 className="text-capitalize">{path}</h4>
        </Fragment>
    )
}
export {PageTitle}