import { Fragment } from "react";
import { PageTabItem } from "../../modules/layout/menu/PageTabItem";
import { Nav } from "react-bootstrap";

const ReservationNav = () => {
  return (
    <Fragment>
      <div className="d-flex justify-content-start align-content-start text-start mb-4 border-bottom p-0">
        <Nav
          variant="pills"
          className="d-flex justify-content-start align-content-start text-start   "
          defaultActiveKey="/listing/all"
        >
          <PageTabItem to="/reservation/list" title="All" />
          <PageTabItem to="/reservation/create" title="Create" />
          <PageTabItem to="/reservation/source" title="Source" />
        </Nav>
      </div>
    </Fragment>
  );
};
export { ReservationNav };
