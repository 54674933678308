import React, { Fragment } from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import { ViewNav } from "./ViewNav";
import NotFound from "../../../NotFound";
import { ViewProperty } from "./ViewProperty";
import { ViewCalendar } from "./ViewCalendar";
import { ViewAll } from "./ViewAll";
import { ViewReservations } from "./ViewReservations";
import { ViewReviews } from "./ViewReviews";
import { ViewCoupons } from "./ViewCoupons";
import { ViewManage } from "./ViewManage";

const ViewPage = () => {
  return (
    <Fragment>
      <Routes>
        <Route
          element={
            <Fragment>
              <ViewProperty />
              <Outlet />
            </Fragment>
          }
        >
          <Route index path=":id" element={<ViewAll />} />
          <Route path="calendar/:id" element={<ViewCalendar />} />
          <Route path="reservations/:id" element={<ViewReservations />} />
          <Route path="reviews/:id" element={<ViewReviews />} />
          <Route path="coupons/:id" element={<ViewCoupons />} />
          <Route path="manage/:id" element={<ViewManage />} />
          <Route index element={<Navigate to="view/:id" />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </Fragment>
  );
};

export { ViewPage };
