import React, { Fragment, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faEnvelope,
  faUnlockAlt,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebookF,
  faGithub,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import {
  Col,
  Row,
  Form,
  Card,
  Button,
  FormCheck,
  Container,
  InputGroup,
  Toast,
  ToastContainer,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { object, string } from "yup";
import { LoginInterface } from "../../modules/models/user.interface";
import { FormButton } from "../../modules/widgets/FormButton";
import { clsx } from "clsx";
import useSignIn from "react-auth-kit/hooks/useSignIn";
import { axiosPost } from "../../modules/helpers/RequestHelper";
import { AssetHelper } from "../../modules/helpers/AssetHelper";

const Signin = () => {
  const signIn = useSignIn();
  const navigate = useNavigate();
  const initialValues: LoginInterface = {
    email: "",
    password: "",
  };
  const validationSchema = object({
    email: string().email().required("Email is required."),
    password: string()
      .min(3, "Password should be more than 3 characters")
      .required("Password is required."),
  });
  const {
    handleSubmit,
    getFieldProps,
    isSubmitting,
    isValid,
    touched,
    errors,
  } = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values, { resetForm, setStatus }) => {
      const auth = await axiosPost("/auth/login", {
        uid: values.email,
        password: values.password,
        role: 1,
      });
      if (auth?.success) {
        signIn({
          auth: {
            token: auth?.token,
            type: "Bearer",
          },
          refresh: "",
          userState: auth?.user,
        });
        setTimeout(() => {
          navigate("/overview");
        }, 500);
      }
    },
  });
  return (
    <Fragment>
      <main>
        <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
          <Container>
            <Row className="justify-content-center form-bg-image">
              <Col
                xs={12}
                className="d-flex align-items-center justify-content-center"
              >
                <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                  <div className="text-center text-md-center mb-4 mt-md-0">
                    <img
                      src={AssetHelper("/assets/img/brand/logo.svg")}
                      alt="logo"
                      height={50}
                    />
                  </div>
                  <Form className="mt-4" onSubmit={handleSubmit}>
                    <Form.Group id="email" className="mb-4">
                      <InputGroup
                        className={clsx(
                          {
                            "is-invalid": touched.email && errors.email,
                          },
                          {
                            "is-valid": touched.email && !errors.email,
                          },
                        )}
                      >
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faEnvelope} />
                        </InputGroup.Text>
                        <Form.Control
                          autoFocus
                          type="email"
                          placeholder="example@joinhomely.com"
                          {...getFieldProps("email")}
                          aria-describedby="emailHelp"
                          name="email"
                        />
                      </InputGroup>
                      {touched.email && errors.email && (
                        <div className="invalid-feedback" id="emailHelp">
                          {errors.email}
                        </div>
                      )}
                    </Form.Group>
                    <Form.Group>
                      <Form.Group id="password" className="mb-4">
                        <InputGroup
                          aria-describedby="passwordHelp"
                          className={clsx(
                            {
                              "is-invalid": touched.email && errors.email,
                            },
                            {
                              "is-valid": touched.email && !errors.email,
                            },
                          )}
                        >
                          <InputGroup.Text>
                            <FontAwesomeIcon icon={faUnlockAlt} />
                          </InputGroup.Text>
                          <Form.Control
                            type="password"
                            placeholder="Password"
                            {...getFieldProps("password")}
                            name="password"
                          />
                        </InputGroup>
                        {touched.password && errors.password && (
                          <div className="invalid-feedback" id="passwordHelp">
                            {errors.password}
                          </div>
                        )}
                      </Form.Group>
                    </Form.Group>
                    <FormButton
                      type="submit"
                      className="w-100"
                      text="Sign in"
                      isSubmitting={isSubmitting}
                      isValid={isValid}
                    />
                  </Form>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </main>
    </Fragment>
  );
};

export { Signin };
