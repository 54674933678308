import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import { Fragment, useEffect } from "react";
import { Signin } from "../pages/auth/Signin";
import { PrivateRoutes } from "./PrivateRoutes";
import { Landing } from "../pages/Landing";
import { Notify } from "../modules/helpers/NotificationHelper";
import NotFound from "../pages/NotFound";
import RequireAuth from "@auth-kit/react-router/RequireAuth";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
const AppRoutes = () => {
  const origin = "/";
  const user: any = useAuthUser();

  useEffect(() => {
    if (!navigator.onLine) {
      Notify({
        type: "toastr",
        toastrProps: {
          text: "Internet connection not available.",
          type: "error",
        },
      });
    }
  }, [navigator.onLine]);

  return (
    <Fragment>
      <BrowserRouter basename={origin}>
        <Routes>
          <Route element={<Landing />}>
            <Route path="/auth/login" element={<Signin />} />
            <Route
              path={"/*"}
              element={
                <RequireAuth fallbackPath={"/auth/login"}>
                  <PrivateRoutes />
                </RequireAuth>
              }
            />
            <Route path="*" element={<Signin />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </Fragment>
  );
};
export { AppRoutes };
