import React from "react";

// core styles
import "./scss/volt.scss";

// vendor styles
import "react-datetime/css/react-datetime.css";
import "line-awesome/dist/line-awesome/css/line-awesome.css";
import "remixicon/fonts/remixicon.css";
// import "@fullcalendar/core/main.css";
// import "@fullcalendar/daygrid/main.css";
// import {ScrollToTop} from "./modules/widgets/ScrollToTop";

import { AppRoutes } from "./router/routes";
import { createRoot } from "react-dom/client";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import createStore from "react-auth-kit/createStore";
import AuthProvider from "react-auth-kit"; // if using DnD

const container = document.getElementById("root");
const store = createStore({
  authName: "_auth",
  authType: "cookie",
  cookieDomain: window.location.hostname,
  cookieSecure: window.location.protocol === "https:",
});
if (container) {
  createRoot(container).render(
    <AuthProvider store={store}>
      {/*<ScrollToTop/>*/}
      <ToastContainer />
      <AppRoutes />
    </AuthProvider>,
  );
}
