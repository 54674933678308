import { FC, Fragment, useEffect, useState } from "react";
import { Table } from "../../../modules/widgets/table/Table";
import { ReservationColumn } from "../../../modules/widgets/table/core/Columns";
import { axiosGet } from "../../../modules/helpers/RequestHelper";
import { useParams } from "react-router-dom";

const ReservationList: FC<{ props?: any }> = ({ props }) => {
  const [data, setData] = useState<any[]>(props || []);
  const { id } = useParams();

  useEffect(() => {
    const fetchReservations = async () => {
      try {
        const req = await axiosGet(`reservation/admin/list/${id ?? ""}`);

        console.log(req);
        if (req?.success) {
          let { reservation } = req;

          // Normalize reservation to an array
          if (!Array.isArray(reservation)) {
            reservation = [reservation];
          }

          // const reserve_list = reservation.filter(
          //   (res: any) => res?.user !== null,
          // );
          setData(reservation);
        }
      } catch (error) {
        console.error("Error fetching reservations:", error);
      }
    };

    fetchReservations();
  }, [id]);

  console.log(data);
  return (
    <Fragment>
      <Table data={data} columns={ReservationColumn} />
    </Fragment>
  );
};
export { ReservationList };
