import {
  DateFormatter,
  PropertyFormatter,
  ReservationStatusFormatter,
  UserFormatter,
} from "../../../helpers/Utils";
import React from "react";
import {
  AmenityColumnAction,
  PlacesColumnAction,
  PropertyCategoryColumnAction,
  ReservationColumnAction,
  SourceColumnAction,
} from "./ColumnAction";
import { clsx } from "clsx";

export const ReservationColumn = [
  {
    name: "Code",
    cell: (row: any) => row?.code,
  },
  {
    name: "Source",
    cell: (row: any) => row?.source?.name,
  },
  {
    name: "Property",
    cell: (row: any) => <PropertyFormatter data={row?.property} />,
  },
  {
    name: "Guest",
    cell: (row: any) =>
      row?.user ? <UserFormatter data={row?.user} /> : "External",
  },
  {
    name: "Checkin",
    cell: (row: any) => <DateFormatter date={row?.log?.checkin} />,
  },
  {
    name: "Checkout",
    cell: (row: any) => <DateFormatter date={row?.log?.checkout} />,
  },
  {
    name: "Status",
    cell: (row: any) => <ReservationStatusFormatter data={row?.status} />,
  },
  {
    name: "Action",
    cell: (row: any) => <ReservationColumnAction slug={row?.code} />,
    ignoreRowClick: true,
    button: true,
  },
];
export const PropertyCategoryColumn = [
  {
    name: "Name",
    selector: (row: any) => row.name,
  },
  {
    name: "Description",
    selector: (row: any) => row.description,
  },
  {
    name: "Status",
    selector: (row: any) => (row?.status ? "Active" : "Inactive"),
  },
  {
    name: "Action",
    cell: (row: any) => <PropertyCategoryColumnAction data={row} />,
    ignoreRowClick: true,
    button: true,
  },
];
export const PropertyAmenityColumn = [
  {
    name: "Name",
    selector: (row: any) => row.name,
  },
  {
    name: "Description",
    selector: (row: any) => row.description,
  },
  {
    name: "Icon",
    selector: (row: any) =>
      row?.slug ? (
        <i className={clsx("fa-2x", row?.slug)}></i>
      ) : (
        "No icon found."
      ),
  },
  {
    name: "Status",
    selector: (row: any) => (row?.status ? "Active" : "Inactive"),
  },
  {
    name: "Action",
    cell: (row: any) => <AmenityColumnAction data={row} />,
    ignoreRowClick: true,
    button: true,
  },
];
export const PlacesColumn = [
  {
    name: "City",
    selector: (row: any) => row?.city,
  },
  {
    name: "Neighborhood",
    selector: (row: any) => row?.neighborhood,
  },
  {
    name: "State",
    selector: (row: any) => row.state?.name,
  },
  {
    name: "Country",
    selector: (row: any) => row.country?.name,
  },
  {
    name: "Status",
    selector: (row: any) => (row?.status ? "Active" : "Inactive"),
  },
  {
    name: "Action",
    cell: (row: any) => <PlacesColumnAction data={row} />,
    ignoreRowClick: true,
    button: true,
  },
];
export const SourceColumn = [
  {
    name: "Name",
    selector: (row: any) => row.name,
  },
  {
    name: "Description",
    selector: (row: any) => row.description,
  },
  {
    name: "Status",
    selector: (row: any) => (row.status == 1 ? "Active" : "Inactive"),
  },
  {
    name: "Action",
    cell: (row: any) => <SourceColumnAction data={row} />,
    ignoreRowClick: true,
    button: true,
  },
];
