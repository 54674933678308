import React, { Fragment, useEffect, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import CurrencyInput from "react-currency-input-field";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faClose } from "@fortawesome/free-solid-svg-icons";
import { FormButton } from "../../../modules/widgets/FormButton";
import { useFormik } from "formik";
import {
  axiosGet,
  axiosPost,
  axiosPut,
} from "../../../modules/helpers/RequestHelper";
import { useNavigate, useParams } from "react-router-dom";
import {
  DragAndDrop,
  SectionLoader,
  useFileSelection,
} from "../../../modules/helpers/Utils";
import { UploadFile, UploadProps } from "antd";
import { Notify } from "../../../modules/helpers/NotificationHelper";

const EditProperty = () => {
  const [data, setData] = useState<any>([]);
  const [category, setCategory] = useState<any[]>([]);
  const [city, setCity] = useState<any[]>([]);
  const [amenity, setAmenity] = useState<any[]>([]);
  const [loadDefaultFileList, setLoadDefaultFileList] = useState(true);
  const { id } = useParams();
  const navigate = useNavigate();
  const [picture, setPicture] = useState<UploadFile[]>([]);
  const [loading, setLoading] = useState(true); // State for loading indicator
  const [error, setError] = useState(false); // State for error handling
  const [errorMessage, setErrorMessage] = useState(""); // Error message state
  const initialValues = {
    name: data?.name,
    internal_name: data?.internal_name,
    description: data?.description,
    available: data?.available,
    availability_date: data?.availability_date,
    status: data?.status,
    service_charge: data?.fee?.service_charge,
    caution_charge: data?.fee?.caution_charge,
    price: data?.fee?.price,
    address: data?.location?.address,
    address_two: data?.location?.address_two,
    apartment_number: data?.location?.apartment_number,
    place: data?.location?.place,
    zipcode: data?.location?.zipcode,
    longitude: data?.location?.longitude,
    latitude: data?.location?.latitude,
    city: data?.location?.place?.id,
    pet: data?.occupancy?.pet,
    max_occupancy: data?.occupancy?.max_occupancy,
    bed: data?.occupancy?.bed,
    bedroom: data?.occupancy?.bedroom,
    bathroom: data?.occupancy?.bathroom,
    categories: data?.category?.id,
    amenities: data?.amenity,
    media: picture,
  };

  const {
    handleSubmit,
    errors,
    touched,
    isSubmitting,
    isValid,
    getFieldProps,
    setFieldValue,
    handleChange,
    values,
    dirty,
    handleBlur,
  } = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting, setStatus, resetForm }) => {
      const availability_date = new Date(values.availability_date);
      try {
        setSubmitting(true);

        if (dirty) {
          const fileList: any = [];
          let existingImage = [];
          let combineImage: any = [];
          for (const file of values.media) {
            if (file.originFileObj) {
              fileList.push(file.originFileObj);
            } else {
              existingImage.push(file);
            }
          }

          if (fileList.length > 0) {
            const formData = new FormData();
            for (const x in fileList) {
              formData.append("pictures", fileList[x]);
            }
            formData.append("type", "1");
            const saveMedia = await axiosPost("/pictures/upload", formData);
            if (saveMedia?.success) {
              const { images } = saveMedia;
              combineImage = existingImage.concat(images);
            }
          } else {
            combineImage = existingImage;
          }
          //
          const amenities = values.amenities.map((item: any) => {
            let id;
            if (item?.accepted_amentity?.id) {
              id = item?.accepted_amentity?.id;
            } else {
              id = item;
            }
            return {
              id,
            };
          });

          const media = combineImage.map((value: any, index: number) => {
            const front = index === 0;
            let id;
            if (value?.uid) {
              id = value?.uid;
            }
            if (value?.id) {
              id = value?.id;
            }
            return { id, front };
          });

          const props = {
            property_id: data?.id,
            name: values.name,
            internal_name: values.internal_name,
            description: values.description,
            available: parseInt(values.available),
            availability_date: availability_date,
            status: parseInt(values.status),
            fee: {
              service_charge: parseInt(values.service_charge),
              caution_charge: parseInt(values.caution_charge),
              price: parseInt(values.price),
            },
            location: {
              address: values.address,
              address_two: values.address_two,
              apartment_number: values.apartment_number,
              place: values.place,
              zipcode: values.zipcode,
              longitude: values.longitude,
              latitude: values.latitude,
            },
            occupancy: {
              pet: parseInt(values.pet),
              max_occupancy: parseInt(values.max_occupancy),
              bed: values.bed,
              bedroom: values.bedroom,
              bathroom: values.bathroom,
            },
            categories_id: values.categories,
            amenities_id: amenities,
            rules: [],
            pictures_id: media,
          };
          const req = await axiosPut("/property/list", props);
          if (req?.success) {
            navigate(-1);
          } else {
            if (Array.isArray(req.message)) {
              for (const message of req.message) {
                Notify({
                  type: "toastr",
                  toastrProps: {
                    text: message.replace(/_/g, " "),
                    type: "error",
                  },
                });
              }
            } else {
              Notify({
                type: "toastr",
                toastrProps: {
                  text: req.message,
                  type: "error",
                },
              });
            }
          }
        } else {
          Notify({
            type: "toastr",
            toastrProps: {
              text: "No Changes made.",
              type: "error",
            },
          });
        }
      } catch (e) {
      } finally {
        setSubmitting(false);
      }
    },
  });
  const handleSort = (sortedFileList: any[]) => {
    setPicture(sortedFileList);
  };
  useEffect(() => {
    (async () => {
      try {
        const property = await axiosGet(`/property/list/${id}`);

        if (property?.success) {
          setData(property?.property);

          const { pictures } = property?.property;
          const imageArr = pictures
            .sort((a: any) => {
              if (a.front) {
                return -1;
              }
            })
            .map((item: any, x: number) => ({
              uid: item.id,
              status: "done",
              name: `${property?.property?.name} Picture ${x}`,
              url: item.url,
            }));
          setPicture(imageArr);
        } else {
          setError(true); // Set error state to true
          setErrorMessage("An error occurred while fetching data."); // Set error message
        }

        const category = await axiosGet("/property/category/list");
        if (category?.success) {
          setCategory(category?.category);
        } else {
          setError(true); // Set error state to true
          setErrorMessage("An error occurred while fetching category."); // Set error message
        }
        const city = await axiosGet("/property/places/list");
        if (city?.success) {
          const { places } = city;

          setCity(places);
        } else {
          setError(true); // Set error state to true
          setErrorMessage("An error occurred while fetching city."); // Set error message
        }
        const amenity = await axiosGet("/property/amenity/list");

        if (amenity?.success) {
          setAmenity(amenity?.amenity);
        } else {
          setError(true); // Set error state to true
          setErrorMessage("An error occurred while fetching amenity."); // Set error message
        }
      } catch (err: any) {
        setError(true); // Set error state to true
        setErrorMessage(
          err.message || "An error occurred while fetching data.",
        ); // Set error message
      } finally {
        setLoading(false); // Set loading state to false regardless of success or failure
      }
    })();
  }, [id]);
  const availa_date = new Date(values?.availability_date);
  const year = availa_date.toLocaleString("default", { year: "numeric" });
  const month = availa_date.toLocaleString("default", { month: "2-digit" });
  const day = availa_date.toLocaleString("default", { day: "2-digit" });

  const formattedDate = year + "-" + month + "-" + day;

  return (
    <Fragment>
      <Card border="light" className="bg-white shadow-sm mb-4">
        <Card.Body>
          {/* Display loading spinner if loading */}
          {loading && <SectionLoader success={false} error={false} />}

          {/* Display error message if error */}
          {error && (
            <div className="alert alert-danger" role="alert">
              {errorMessage}
            </div>
          )}

          {/* Display content if not loading and no error */}
          {!loading && !error && (
            <>
              <h5 className="mb-4">Edit Property - {data?.name}</h5>

              <Form className="mt-5" onSubmit={handleSubmit}>
                <div className="my-5">
                  <div className="row d-flex justify-content-between">
                    <div className="col-md-8">
                      <h5 className="fw-light lead text-underline">General</h5>
                      <small className="text-light-emphasis">
                        Fill in general information about property.
                      </small>
                    </div>

                    <div className="col-md-4">
                      <Form.Group>
                        <Form.Select
                          aria-label="Property status"
                          isValid={errors.status === undefined}
                          isInvalid={errors.status !== undefined}
                          className="p-3"
                          {...getFieldProps("status")}
                          name="status"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          <option value="1" selected={values?.status == 1}>
                            Publish
                          </option>
                          <option value="2" selected={values?.status == 2}>
                            Draft
                          </option>
                        </Form.Select>
                      </Form.Group>
                    </div>
                  </div>
                </div>

                <Row>
                  <Form.Group as={Col} md={4} className="mb-3">
                    <Form.Label>Property name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter property name"
                      className="p-3"
                      {...getFieldProps("name")}
                      name="name"
                      value={values?.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Form.Group>
                  <Form.Group as={Col} md={4} className="mb-3">
                    <Form.Label>Property Internal Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter property inetnal name"
                      className="p-3"
                      {...getFieldProps("internal_name")}
                      name="internal_name"
                      value={values?.internal_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Form.Group>
                  <Form.Group as={Col} md={4} className="mb-3">
                    <Form.Label>Property Category</Form.Label>
                    <Form.Select
                      aria-label="Property category"
                      className="p-3"
                      {...getFieldProps("categories")}
                      name="categories"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option value="">Choose property category</option>
                      {category?.length > 0 &&
                        category.map((item: any) => (
                          <option
                            key={item?.id}
                            value={item?.id}
                            selected={values.categories === item.id}
                          >
                            {item?.name}
                          </option>
                        ))}
                    </Form.Select>
                  </Form.Group>
                  <Form.Group as={Col} md={6} className="mb-3">
                    <Form.Label>Availability date</Form.Label>
                    <Form.Control
                      type="date"
                      placeholder="Enter property name"
                      className="p-3"
                      {...getFieldProps("availability_date")}
                      name="availability_date"
                      value={formattedDate}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Form.Group>
                  <Form.Group as={Col} md={6} className="mb-3">
                    <Form.Label>Available</Form.Label>
                    <Form.Select
                      aria-label="Property available"
                      className="p-3"
                      {...getFieldProps("available")}
                      name="available"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option value="1" selected={values?.available == 1}>
                        Yes
                      </option>
                      <option value="0" selected={values?.available == 0}>
                        No
                      </option>
                    </Form.Select>
                  </Form.Group>

                  <Form.Group as={Col} md={12} className="mb-3">
                    <Form.Label>Property description</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      placeholder="Enter property description"
                      className="p-4"
                      {...getFieldProps("description")}
                      name="description"
                      value={values?.description}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Form.Group>
                </Row>
                <h5 className="fw-light my-3 text-underline">Fees</h5>
                <Row>
                  <Form.Group as={Col} md={4} className="mb-3">
                    <Form.Label>Price</Form.Label>
                    <CurrencyInput
                      prefix="&#8358;"
                      className="form-control p-3"
                      placeholder="Enter property price per night"
                      name="price"
                      value={values?.price}
                      onValueChange={(value) => setFieldValue("price", value)}
                    />
                  </Form.Group>

                  <Form.Group as={Col} md={4} className="mb-3">
                    <Form.Label>Service charge</Form.Label>
                    <CurrencyInput
                      prefix="&#8358;"
                      className="form-control p-3"
                      placeholder="Enter property service charge"
                      name="service_charge"
                      value={values?.service_charge}
                      onValueChange={(value) =>
                        setFieldValue("service_charge", value)
                      }
                    />
                  </Form.Group>
                  <Form.Group as={Col} md={4} className="mb-3">
                    <Form.Label>Caution fee</Form.Label>
                    <CurrencyInput
                      prefix="&#8358;"
                      className="form-control p-3"
                      placeholder="Enter property caution fee"
                      name="caution_fee"
                      value={values?.caution_charge}
                      onValueChange={(value) =>
                        setFieldValue("caution_charge", value)
                      }
                    />
                  </Form.Group>
                </Row>
                <h5 className="fw-light my-3 text-underline">Occupancy</h5>
                <Row>
                  <Form.Group as={Col} md={6} className="mb-3">
                    <Form.Label>Max occupancy</Form.Label>
                    <Form.Control
                      type="tel"
                      placeholder="Enter Max Occupancy"
                      className="p-3"
                      {...getFieldProps("max_occupancy")}
                      name="max_occupancy"
                      value={values?.max_occupancy}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Form.Group>

                  <Form.Group as={Col} md={6} className="mb-3">
                    <Form.Label>Pets Allowed</Form.Label>
                    <Form.Select
                      aria-label="Pets allowed"
                      className="p-3"
                      {...getFieldProps("pet")}
                      name="pet"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option value="">Choose Pets Allowed</option>
                      <option key={0} value="1" selected={values?.pet === 1}>
                        Yes
                      </option>
                      <option key={1} value="0" selected={values?.pet === 2}>
                        No
                      </option>
                    </Form.Select>
                  </Form.Group>
                  <Form.Group as={Col} md={4} className="mb-3">
                    <Form.Label>Bedroom</Form.Label>
                    <Form.Control
                      type="tel"
                      placeholder="Enter no. of bedroom"
                      className="p-3"
                      {...getFieldProps("bedroom")}
                      name="bedroom"
                      value={values?.bedroom}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Form.Group>

                  <Form.Group as={Col} md={4} className="mb-3">
                    <Form.Label>Bed</Form.Label>
                    <Form.Control
                      type="tel"
                      placeholder="Enter no. of bed"
                      className="p-3"
                      {...getFieldProps("bed")}
                      name="bed"
                      value={values?.bed}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Form.Group>

                  <Form.Group as={Col} md={4} className="mb-3">
                    <Form.Label>Bathroom</Form.Label>
                    <Form.Control
                      type="tel"
                      placeholder="Enter no. of bathroom"
                      className="p-3"
                      {...getFieldProps("bathroom")}
                      name="bathroom"
                      value={values?.bathroom}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Form.Group>
                </Row>
                <div className="my-3">
                  <h5 className="fw-light lead text-underline">Location </h5>
                  <small className="text-light-emphasis">
                    Fill in property location, including address, latitude &
                    longitude for map.
                  </small>
                </div>

                <Row>
                  <Form.Group as={Col} md={4} className="mb-3">
                    <Form.Label>Address</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter address"
                      className="p-3"
                      {...getFieldProps("address")}
                      name="address"
                      value={values?.address}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Form.Group>
                  <Form.Group as={Col} md={4} className="mb-3">
                    <Form.Label>Address Two (Optional)</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter address two (optional)"
                      className="p-3"
                      {...getFieldProps("address_two")}
                      name="address_two"
                      value={values?.address_two}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Form.Group>
                  <Form.Group as={Col} md={4} className="mb-3">
                    <Form.Label>City</Form.Label>
                    <Form.Select
                      aria-label="location"
                      className="p-3"
                      {...getFieldProps("city")}
                      name="city"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      {city?.length > 0 &&
                        city.map((item: any) => (
                          <option
                            key={item?.id}
                            value={item?.id}
                            selected={values?.city === item.id}
                          >
                            {item?.city}, {item?.state?.name},{" "}
                            {item?.country?.name}{" "}
                          </option>
                        ))}
                    </Form.Select>
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group as={Col} md={3} className="mb-3">
                    <Form.Label>Apartment no</Form.Label>
                    <Form.Control
                      type="tel"
                      placeholder="Enter apartment no."
                      className="p-3"
                      {...getFieldProps("apartment_number")}
                      name="apartment_number"
                      value={values?.apartment_number}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Form.Group>
                  <Form.Group as={Col} md={3} className="mb-3">
                    <Form.Label>Zip code</Form.Label>
                    <Form.Control
                      type="tel"
                      placeholder="Enter zipcode"
                      className="p-3"
                      {...getFieldProps("zipcode")}
                      name="zipcode"
                      value={values?.zipcode}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Form.Group>
                  <Form.Group as={Col} md={3} className="mb-3">
                    <Form.Label>Longitude</Form.Label>
                    <Form.Control
                      type="tel"
                      placeholder="Enter longitude"
                      className="p-3"
                      {...getFieldProps("longitude")}
                      name="longitude"
                      value={values?.longitude}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Form.Group>
                  <Form.Group as={Col} md={3} className="mb-3">
                    <Form.Label>Latitude</Form.Label>
                    <Form.Control
                      type="tel"
                      placeholder="Enter latitude"
                      className="p-3"
                      {...getFieldProps("latitude")}
                      name="latitude"
                      value={values?.latitude}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Form.Group>
                </Row>
                <div className="row d-flex justify-content-between my-3">
                  <div className="col-md-6">
                    <h5 className="fw-light text-underline">Amenities</h5>
                    <small className="text-light-emphasis">
                      Fill in general information about property.
                    </small>
                  </div>

                  <div className="d-flex flex-wrap gap-2 my-2">
                    {amenity?.length > 0 &&
                      amenity
                        .sort()
                        .map((item: any, i: number) => (
                          <Form.Check
                            key={i}
                            type="checkbox"
                            checked={values.amenities.find(
                              (amenity: any) =>
                                amenity?.accepted_amentity?.id === item?.id,
                            )}
                            {...getFieldProps("amenities")}
                            name="amenities"
                            value={item.id}
                            onChange={handleChange}
                            id={item?.id}
                            label={item?.name}
                          />
                        ))}
                  </div>
                </div>
                {/*<div className="row d-flex justify-content-between my-3">*/}
                {/*  <div className="col-md-6">*/}
                {/*    <h5 className="fw-light text-underline">Rules</h5>*/}
                {/*    <small className="text-light-emphasis">*/}
                {/*      Fill in general information about property.*/}
                {/*    </small>*/}
                {/*  </div>*/}

                {/*  <div className="col-md-6">*/}
                {/*    <ul className="todo-list d-flex  flex-wrap flex-row list-style-none ">*/}
                {/*      {rules.map((todo: any) => (*/}
                {/*        <li*/}
                {/*          className="bg-secondary rounded-xl mb-3 ms-2 fs-6 fw-light p-1"*/}
                {/*          key={todo.id}*/}
                {/*        >*/}
                {/*          {todo.name}*/}
                {/*          <Button*/}
                {/*            className="rounded-xl btn-sm bg-transparent border-0 shadow-none shadow"*/}
                {/*            variant="light"*/}
                {/*          >*/}
                {/*            <FontAwesomeIcon icon={faClose} />*/}
                {/*          </Button>*/}
                {/*        </li>*/}
                {/*      ))}*/}
                {/*    </ul>*/}
                {/*  </div>*/}
                {/*</div>*/}
                {/*<Row className="">*/}
                {/*  <Col md={6}>*/}
                {/*    <Form.Group className="mb-3">*/}
                {/*      <Form.Label>Title</Form.Label>*/}
                {/*      <Form.Control*/}
                {/*        type="text"*/}
                {/*        className="p-3"*/}
                {/*        placeholder="Enter rule title  Eg. Checkout Time"*/}
                {/*        name="name"*/}
                {/*        value={rule.name}*/}
                {/*        onChange={handleChange}*/}
                {/*        onBlur={handleBlur}*/}
                {/*      />*/}
                {/*    </Form.Group>*/}
                {/*    <Button className="btn-sm " variant="primary">*/}
                {/*      Add Rule <FontAwesomeIcon icon={faArrowRight} />*/}
                {/*    </Button>*/}
                {/*  </Col>*/}
                {/*  <Col md={6}>*/}
                {/*    <Form.Group className="mb-3">*/}
                {/*      <Form.Label>Rule</Form.Label>*/}
                {/*      <Form.Control*/}
                {/*        type="text"*/}
                {/*        className="p-3"*/}
                {/*        placeholder="Enter rule instruction  Eg. Time 12:00pm "*/}
                {/*        name="instruction"*/}
                {/*        value={rule.instruction}*/}
                {/*        onChange={handleChange}*/}
                {/*        onBlur={handleBlur}*/}
                {/*      />*/}
                {/*    </Form.Group>*/}
                {/*  </Col>*/}
                {/*</Row>*/}

                <div className="row d-flex justify-content-between align-items-center my-3">
                  <div className="col-md-6">
                    <h5 className="fw-light text-underline">Media & Gallery</h5>
                    <small className="text-light-emphasis">
                      Fill in general information about property.
                    </small>
                  </div>
                </div>
                <Row className="mb-3">
                  <Col md={12}>
                    <DragAndDrop
                      onSort={handleSort}
                      className=""
                      fileList={picture.sort()}
                      name="media"
                      setFieldValue={setFieldValue}
                    />
                  </Col>
                </Row>

                <Row className="mt-5 d-flex justify-content-start">
                  <Col md={3}>
                    <FormButton
                      type="submit"
                      text="Continue"
                      isSubmitting={isSubmitting}
                      isValid={isValid}
                      isUpdate={dirty}
                      className="w-100 p-3"
                      variant="primary"
                    />
                  </Col>
                </Row>
              </Form>
            </>
          )}
        </Card.Body>
      </Card>
    </Fragment>
  );
};
export { EditProperty };
