import {Fragment} from "react";
import {AppLayout} from "../modules/layout/AppLayout";
import {Outlet} from "react-router-dom";

const AppLanding = ()=> {
    return (
        <Fragment>
            <AppLayout>
                <Outlet/>
            </AppLayout>
        </Fragment>
    )
}
export {AppLanding}