import React, { Fragment, useEffect, useState } from "react";
import {
  axiosDelete,
  axiosGet,
  axiosSpecial,
} from "../../../modules/helpers/RequestHelper";
import {
  CurrencyHelper,
  DateFormatter,
  SectionLoader,
} from "../../../modules/helpers/Utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card, Col, Dropdown, Pagination, Row } from "react-bootstrap";
import {
  faEllipsisH,
  faCircle,
  faExternalLink,
  faBuilding,
} from "@fortawesome/free-solid-svg-icons";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";

const PropertyList = () => {
  const [data, setData] = useState([]);
  const [isDelete, setIsDelete] = useState(false);
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 6;
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = data.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(data.length / itemsPerPage);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const fetchData = async () => {
    setLoading(true);
    setError(false);
    try {
      const req = await axiosGet("/property/list/all");
      if (req?.success) {
        setData(req.properties);
      } else {
        setError(true);
        setErrorMessage("An error occurred while fetching data.");
      }
    } catch (err: any) {
      setError(true);
      setErrorMessage(err.message || "An error occurred while fetching data.");
    } finally {
      setLoading(false);
    }
  };

  const deleteData = async (id: any) => {
    try {
      const req = await axiosDelete(`/property/list/${id}`);
      if (req?.success) {
        setIsDelete(true);
      }
    } catch (error) {
      console.error("Error deleting property:", error);
    }
  };

  const updateAvailability = async (id: any, available: any) => {
    try {
      const req = await axiosSpecial({
        path: `/property/update-availability/${id}`,
        data: {
          available: available ? 1 : 0,
        },
        title: `Do you want to make property ${available ? "Unavailable" : "Available"}?`,
        type: available ? "Set Unavailable" : "Set Available",
      });
      if (req?.success) {
        setIsDelete(true);
      }
    } catch (error) {
      console.error("Error updating property availability:", error);
    }
  };
  const handleListingChange = async (id: any, status: any) => {
    try {
      const response = await axiosSpecial({
        path: `/property/update-status/${id}`,
        data: {
          status: status ? 1 : 0,
        },
        title: `Do you want to take property ${status ? "Offline" : "Online"}`,
        type: status ? "Take Offline" : "Make Online",
      });
      if (response.success) {
        setIsDelete(true);
      }
    } catch (error) {
      console.error("Error updating property status:", error);
    }
  };

  useEffect(() => {
    if (isDelete) {
      fetchData();
      setIsDelete(false);
    } else {
      fetchData();
    }
  }, [isDelete, itemOffset]);

  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * itemsPerPage) % data.length;
    setItemOffset(newOffset);
  };

  return (
    <Fragment>
      {loading && <SectionLoader success={false} error={false} />}

      {error && (
        <div className="alert alert-danger" role="alert">
          {errorMessage}
        </div>
      )}

      {!loading && !error && (
        <div>
          <Row>
            <Col md={4}>
              <Card style={{ width: "18rem" }} className="border-0">
                <Card.Body>
                  <Card.Title className="me-2 fw-medium">Properties</Card.Title>
                  <Card.Text className="text-success fs-4">
                    {data.length}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
              <Card style={{ width: "18rem" }} className="border-0">
                <Card.Body>
                  <Card.Title className="me-2 fw-medium">
                    Available Properties
                  </Card.Title>
                  <Card.Text className="text-success fs-4">
                    {data.filter((item: any) => item.available).length}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
              <Card style={{ width: "18rem" }} className="border-0">
                <Card.Body>
                  <Card.Title className="me-2 fw-medium">
                    Booked Properties
                  </Card.Title>
                  <Card.Text className="text-success fs-4">
                    {data.filter((item: any) => item.status === 0).length}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <hr />

          <Row className="my-4 d-flex flex-wrap">
            {currentItems.length > 0 &&
              currentItems.map((item: any) => (
                <div className="col-md-4 mb-4 d-flex" key={item.id}>
                  <div
                    className="card d-flex flex-column position-relative w-100"
                    style={{ minHeight: "200px" }}
                  >
                    <div className="position-absolute bg-white p-1 rounded-2">
                      {item.available === 1 && item.status === 1 && (
                        <Fragment>
                          <FontAwesomeIcon
                            icon={faCircle}
                            style={{ color: "#32CD32" }}
                          />
                          <span className="text-muted ms-1">Online</span>
                        </Fragment>
                      )}
                      {item.available === 0 && (
                        <Fragment>
                          <FontAwesomeIcon
                            icon={faCircle}
                            className="text-secondary"
                          />
                          <span className="text-muted ms-1">Unavailable</span>
                        </Fragment>
                      )}
                      {item.status === 0 && (
                        <Fragment>
                          <FontAwesomeIcon
                            icon={faCircle}
                            className="text-danger"
                          />
                          <span className="text-muted ms-1">Offline</span>
                        </Fragment>
                      )}
                    </div>

                    {item.pictures.length > 0 &&
                      item.pictures.map(
                        (pic: any) =>
                          pic.front && (
                            <img
                              key={pic.id}
                              src={pic.url}
                              className="card-img-top"
                              alt={item.name}
                              height={100}
                              width={100}
                              style={{ minWidth: "300px", minHeight: "300px" }}
                            />
                          ),
                      )}
                    <div className="card-body p-2 d-flex flex-column flex-grow-1">
                      <Row className="d-flex align-items-baseline mb-3">
                        <Col md={10}>
                          <h5 className="card-title text-capitalize mb-0">
                            {item.name.replace(/-/g, " ")}
                          </h5>
                          <small> {item.internal_name}</small>
                        </Col>
                        <Col
                          md={2}
                          className="d-flex m-0 justify-content-end align-items-center"
                        >
                          <Dropdown className="p-0 m-0">
                            <Dropdown.Toggle
                              variant="light"
                              className="bg-transparent border-0 shadow-none"
                              id="dropdown-basic"
                            >
                              <FontAwesomeIcon icon={faEllipsisH} />
                            </Dropdown.Toggle>

                            <Dropdown.Menu style={{ fontSize: "12px" }}>
                              <Dropdown.Item
                                target="_blank"
                                href={`${process.env.REACT_APP_CLIENT_URL}/home/${item.slug}`}
                              >
                                Open <FontAwesomeIcon icon={faExternalLink} />
                              </Dropdown.Item>
                              <Dropdown.Item
                                as={Link}
                                to={`/listing/view/${item.slug}`}
                              >
                                View
                              </Dropdown.Item>

                              <Dropdown.Item
                                as={Link}
                                to={`/listing/edit/${item.slug}`}
                              >
                                Edit
                              </Dropdown.Item>

                              {item.available === 1 && (
                                <Dropdown.Item
                                  as="button"
                                  onClick={() => updateAvailability(item.id, 0)}
                                >
                                  Make Unavailable
                                </Dropdown.Item>
                              )}
                              {item.available === 0 && (
                                <Dropdown.Item
                                  as="button"
                                  onClick={() => updateAvailability(item.id, 1)}
                                >
                                  Make Available
                                </Dropdown.Item>
                              )}
                              {item.status === 1 && (
                                <Dropdown.Item
                                  as="button"
                                  onClick={() =>
                                    handleListingChange(item.id, 0)
                                  }
                                >
                                  Take Offline
                                </Dropdown.Item>
                              )}
                              {item.status === 0 && (
                                <Dropdown.Item
                                  as="button"
                                  onClick={() =>
                                    handleListingChange(item.id, 1)
                                  }
                                >
                                  Take Online
                                </Dropdown.Item>
                              )}
                              <Dropdown.Item
                                as="button"
                                onClick={() => deleteData(item.id)}
                              >
                                Delete
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </Col>
                      </Row>
                      <div className="row flex-grow-1">
                        <div className="col-md-12">
                          <p className="card-text">
                            Price: <CurrencyHelper price={item.fee.price} />
                          </p>
                        </div>
                        <div className="col-md-12">
                          <p className="card-text">
                            Available: {item.available === 1 ? "Yes" : "No"}
                          </p>
                        </div>
                        <div className="col-md-12">
                          <p className="card-text">
                            Availability:{" "}
                            <DateFormatter date={item.availability_date} />
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </Row>

          <Pagination>
            <ReactPaginate
              breakLabel="..."
              nextLabel="Next"
              onPageChange={handlePageClick}
              pageRangeDisplayed={5}
              pageCount={pageCount}
              previousLabel="Previous"
              className="d-flex justify-content-between align-items-center gap-3 list-style-none"
              renderOnZeroPageCount={null}
              containerClassName="pagination"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              activeClassName="text-white"
              activeLinkClassName="bg-secondary"
            />
          </Pagination>
        </div>
      )}
    </Fragment>
  );
};

export { PropertyList };
