import {Fragment} from "react";
import { Outlet } from "react-router-dom";

const Landing = ()=> {

    return (
        <Fragment>
            <Outlet/>
        </Fragment>
    )
}
export {Landing}