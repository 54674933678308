import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import DashboardOverview from "../pages/dashboard/DashboardOverview";
import { Fragment } from "react";
import { AppLanding } from "../pages/AppLanding";
import { PropertyPage } from "../pages/property/PropertyPage";
import NotFound from "../pages/NotFound";
import { ReservationPage } from "../pages/reservation/ReservationPage";
import { CalendarPage } from "../pages/calendar/Calendar";

const PrivateRoutes = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const returnValue = searchParams.get("return");
  const returnRoute = returnValue ?? "/listings/all";
  return (
    <Fragment>
      <Routes>
        <Route element={<AppLanding />}>
          <Route path="auth/*" element={<Navigate to={returnRoute} />} />
          <Route path="/overview" element={<DashboardOverview />} />
          <Route path="/listing/*" element={<PropertyPage />} />
          <Route path="/reservation/*" element={<ReservationPage />} />
          <Route path="/calendar" element={<CalendarPage />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </Fragment>
  );
};
export { PrivateRoutes };
