import React, { FC, Fragment, ReactNode } from "react";
import { Link, useLocation } from "react-router-dom";
import { clsx } from "clsx";
import { Nav } from "react-bootstrap";

type Props = {
  to: string;
  title: string;
  icon?: string;
  fontIcon?: string;
  hasBullet?: boolean;
  action?: any;
  access?: boolean | true | false;
  childrenId?: string;
  iconType?: string;
  className?: string;
};

export type WithChildren = {
  children?: ReactNode;
};

export function getCurrentUrl(pathname: string) {
  return pathname.split(/[?#]/)[0];
}
export function checkIsActive(pathname: string, url: string) {
  const current = getCurrentUrl(pathname);
  if (!current || !url) {
    return false;
  }

  if (current === url) {
    return true;
  }

  return current.indexOf(url) > -1;
}
const PageTabItem: FC<Props & WithChildren> = ({
  className,
  children,
  childrenId,
  to,
  title,
  icon,
  hasBullet = false,
  action,
  access = true,
}) => {
  const { pathname } = useLocation();
  const isActive = checkIsActive(pathname, to);

  return (
    <Fragment>
      <Nav.Item>
        <Nav.Link
          className={clsx("nav-link ms-2", { "hm-link-active": isActive })}
          aria-current={isActive && "page"}
          to={to}
          as={Link}
          eventKey={title.toLowerCase()}
        >
          {" "}
          {icon} {title}
        </Nav.Link>
      </Nav.Item>
    </Fragment>
  );
};

export { PageTabItem };
