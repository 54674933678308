import React, { Fragment, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { axiosGet } from "../../../modules/helpers/RequestHelper";
import { Accordion, Card, Col, Dropdown, Row } from "react-bootstrap";
import { DateTime } from "luxon";
import { ReservationList } from "./ReservationList";
import {
  CurrencyHelper,
  DateFormatter,
  ReservationStatusFormatter,
  SectionLoader,
} from "../../../modules/helpers/Utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import { ReservationStatus } from "../../../modules/models/reservation.interface";

const ReservationView = () => {
  const { id } = useParams();
  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  useEffect(() => {
    (async () => {
      try {
        const req = await axiosGet(`/reservation/list/${id}`);

        if (req?.success) {
          setData(req?.reservation);
        }
      } catch (err: any) {
        setError(true);
        setErrorMessage(
          err.message || "An error occurred while fetching data.",
        );
      } finally {
        setLoading(false);
      }
    })();
  }, [id]);
  const checkinDate = DateTime.fromISO(data?.log?.checkin);
  const checkoutDate = DateTime.fromISO(data?.log?.checkout);

  // Calculating the number of days between check-in and check-out
  const numberOfDays = checkoutDate.diff(checkinDate, "days").days;

  console.log(data);
  return (
    <Fragment>
      <Card>
        {!loading && !error && (
          <Card.Header className="d-flex align-items-baseline justify-content-between">
            <div className="d-flex justify-content-start gap-3 align-items-baseline">
              <small>
                <span className="fw-bold">Reservation Date</span>:{" "}
                <DateFormatter date={data?.created} />{" "}
              </small>
              <small>
                <span className="fw-bold">Status</span>:{" "}
                <ReservationStatusFormatter data={data?.status} />
              </small>
            </div>
            <div className="d-flex justify-content-end gap-3 align-items-baseline">
              <Dropdown style={{ fontSize: "12px" }}>
                <Dropdown.Toggle className="bg-transparent border-0 text-black">
                  <FontAwesomeIcon icon={faEllipsisH} />
                </Dropdown.Toggle>

                <Dropdown.Menu style={{ fontSize: "12px" }}>
                  <Dropdown.Item
                    as={Link}
                    to={`#`}
                    disabled={data?.status === ReservationStatus.COMPLETED}
                  >
                    Edit
                  </Dropdown.Item>
                  <Dropdown.Item as={Link} to={`#`}>
                    Export
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </Card.Header>
        )}
        <Card.Body>
          {loading && <SectionLoader success={false} error={false} />}

          {error && (
            <div className="alert alert-danger" role="alert">
              {errorMessage}
            </div>
          )}
          {!loading && !error && (
            <Accordion defaultActiveKey="0">
              <Accordion.Item
                eventKey="0"
                className="bg-transparent border-0 mb-4"
              >
                <Accordion.Header className="px-0 bg-transparent border-b-0 rounded-0">
                  <h5 className="fw-light lead ">
                    <span className="fw-bold">Guest Information</span>
                    {data?.user
                      ? ` : ${data.user.firstname} ${data.user.lastname}`
                      : ""}
                  </h5>
                </Accordion.Header>
                <Accordion.Body className="px-0">
                  <section className="d-flex justify-content-between flex-column align-items-start gap-5">
                    <div key={0} className="hm-accordion-item">
                      <div className="mb-3 hm-accordion-header d-flex justify-content-between">
                        <h5 className="fw-light lead text-underline">
                          Personal
                        </h5>
                      </div>
                      <div className="d-flex justify-content-start gap-5 hm-accordion-body flex-wrap">
                        <div className="hm-reservation-info">
                          <span className="fw-bold">First Name </span>:{" "}
                          <span>{data?.user?.firstname ?? "n/a"}</span>
                        </div>
                        <div className="hm-reservation-info">
                          <span className="fw-bold">Last Name </span>:{" "}
                          <span>{data?.user?.lastname ?? "n/a"}</span>
                        </div>
                        <div className="hm-reservation-info">
                          <span className="fw-bold">Middle Name </span>:{" "}
                          <span>{data?.user?.middlename ?? "n/a"}</span>
                        </div>
                        <div className="hm-reservation-info">
                          <span className="fw-bold">Email </span>:{" "}
                          <span>{data?.user?.email ?? "n/a"}</span>
                        </div>
                        <div className="hm-reservation-info">
                          <span className="fw-bold">Phone </span>:{" "}
                          <span>{data?.user?.phone ?? "n/a"}</span>
                        </div>
                        <div className="hm-reservation-info">
                          <span className="fw-bold">Fax </span>:{" "}
                          <span>{data?.user?.fax ?? "n/a"}</span>
                        </div>
                      </div>
                    </div>
                    <div key={1} className="hm-accordion-item">
                      <div className="mb-3 hm-accordion-header d-flex justify-content-start">
                        <h5 className="fw-light lead text-underline">
                          Location
                        </h5>
                      </div>
                      <div className="d-flex justify-content-start gap-5 hm-accordion-body flex-wrap">
                        <div className="hm-reservation-info">
                          <span className="fw-bold">Government Issued ID </span>
                          :{" "}
                          <span>
                            {data?.user?.kyc?.government_id
                              ? `<a href={data?.user?.kyc?.government_id} className="btn btn-link text-primary">View</a>`
                              : "n/a"}
                          </span>
                        </div>
                        <div className="hm-reservation-info">
                          <span className="fw-bold">Address </span>:{" "}
                          <span>
                            {data?.property?.location?.address ?? "n/a"}
                          </span>
                        </div>
                        <div className="hm-reservation-info">
                          <span className="fw-bold">City</span>:{" "}
                          <span>
                            {data?.property?.location?.place?.city ?? "n/a"}
                          </span>
                        </div>
                        <div className="hm-reservation-info">
                          <span className="fw-bold">Neighborhood </span>:{" "}
                          <span>
                            {data?.property?.location?.place?.neighborhood ??
                              "n/a"}
                          </span>
                        </div>
                        <div className="hm-reservation-info">
                          <span className="fw-bold">Zipcode </span>:{" "}
                          <span>
                            {data?.property?.location?.zipcode ?? "n/a"}
                          </span>
                        </div>
                        <div className="hm-reservation-info">
                          <span className="fw-bold">State </span>:{" "}
                          <span>
                            {data?.property?.location?.place?.state ?? "n/a"}
                          </span>
                        </div>
                        <div className="hm-reservation-info">
                          <span className="fw-bold">Country </span>:{" "}
                          <span>
                            {data?.property?.location?.place?.country ?? "n/a"}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div key={2} className="hm-accordion-item">
                      <div className="mb-3 hm-accordion-header d-flex justify-content-between">
                        <h5 className="fw-light lead text-underline">
                          Occupancy
                        </h5>
                      </div>
                      <div className="d-flex justify-content-start gap-5 hm-accordion-body flex-wrap">
                        <div className="hm-reservation-info">
                          <span className="fw-bold">No of Guests </span>:{" "}
                          <span>
                            {data?.occupancy?.adult +
                              data?.occupancy?.children ?? 0}
                          </span>
                        </div>
                        <div className="hm-reservation-info">
                          <span className="fw-bold">Adult(s)</span>:{" "}
                          <span>{data?.occupancy?.adult ?? 0}</span>
                        </div>
                        <div className="hm-reservation-info">
                          <span className="fw-bold">Children </span>:{" "}
                          <span>{data?.occupancy?.children ?? 0}</span>
                        </div>{" "}
                        <div className="hm-reservation-info">
                          {" "}
                          <span className="fw-bold">Pet </span>:{" "}
                          <span>
                            {data?.occupancy?.pet === 0 ? "No" : "Yes" ?? "No"}
                          </span>
                        </div>
                        <div className="hm-reservation-info">
                          <span className="fw-bold">Check-in Date: </span>
                          <span>
                            <DateFormatter date={data?.log?.checkin} />
                          </span>
                        </div>
                        <div className="hm-reservation-info">
                          <span className="fw-bold">Check-out Date: </span>
                          <span>
                            <DateFormatter date={data?.log?.checkout} />
                          </span>
                        </div>
                        <div className="hm-reservation-info">
                          <span className="fw-bold">Total Days: </span>
                          <span>{numberOfDays > 0 ? numberOfDays : "N/A"}</span>
                        </div>
                      </div>
                    </div>
                    <div key={3} className="hm-accordion-item">
                      <div className="mb-3 hm-accordion-header d-flex justify-content-between">
                        <h5 className="fw-light lead text-underline">Fees</h5>
                      </div>
                      <div className="d-flex justify-content-start gap-5 hm-accordion-body flex-wrap">
                        <div className="hm-reservation-info">
                          <span className="fw-bold">Price per Night: </span>
                          <span>
                            {
                              <CurrencyHelper
                                price={data?.property?.fee?.price}
                              />
                            }
                          </span>
                        </div>
                        <div className="hm-reservation-info">
                          <span className="fw-bold">Service Charge: </span>
                          <span>
                            {
                              <CurrencyHelper
                                price={data?.property?.fee?.service_charge}
                              />
                            }
                          </span>
                        </div>
                        <div className="hm-reservation-info">
                          <span className="fw-bold">Caution Charge: </span>
                          <span>
                            {
                              <CurrencyHelper
                                price={data?.property?.fee?.caution_charge}
                              />
                            }
                          </span>
                        </div>
                        <div className="hm-reservation-info">
                          <span className="fw-bold">Caution Charge: </span>
                          <span>
                            {
                              <CurrencyHelper
                                price={
                                  data?.property?.discount ?? "Not applicable"
                                }
                              />
                            }
                          </span>
                        </div>
                        <div className="hm-reservation-info">
                          <span className="fw-bold">Total Amount: </span>
                          <span>
                            {
                              <CurrencyHelper
                                price={
                                  data?.property?.fee?.price +
                                  data?.property?.fee?.caution_charge +
                                  data?.property?.fee?.service_charge
                                }
                              />
                            }
                          </span>
                        </div>
                      </div>
                    </div>
                    <div key={4} className="hm-accordion-item">
                      <div className="mb-3 hm-accordion-header d-flex justify-content-between">
                        <h5 className="fw-light lead text-underline">Extra</h5>
                      </div>
                      <div className="d-flex justify-content-start gap-5 hm-accordion-body flex-wrap">
                        <div className="hm-reservation-info">
                          <span className="fw-bold">Guest Type: </span>
                          <span>{data?.guesttype ?? "n/a"}</span>
                        </div>
                        <div className="hm-reservation-info">
                          <span className="fw-bold">Purpose of Stay: </span>
                          <span>{data?.purpose ?? "n/a"}</span>
                        </div>
                        <div className="hm-reservation-info">
                          <span className="fw-bold">
                            How did guest learn about us:{" "}
                          </span>
                          <span>{data?.learnabout ?? "n/a"}</span>
                        </div>
                        <div className="hm-reservation-info">
                          <span className="fw-bold">Extra service: </span>
                          <span>{data?.extraservice ?? "n/a"}</span>
                        </div>
                      </div>
                    </div>
                  </section>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1" className="bg-transparent border-0">
                <Accordion.Header className="px-0 bg-transparent border-b-0 rounded-0">
                  <h5 className="fw-light lead ">
                    <span className="fw-bold">Property Information</span>
                    {data?.property ? ` : ${data.property.internal_name}` : ""}
                  </h5>
                </Accordion.Header>
                <Accordion.Body className="px-0">
                  <section className="d-flex justify-content-between flex-column align-items-start gap-5">
                    <div key={0} className="hm-accordion-item">
                      <div className="mb-3 hm-accordion-header d-flex justify-content-start gap-3 align-items-baseline">
                        <h5 className="fw-light lead text-underline ">
                          General
                        </h5>
                        <Link
                          className="text-secondary"
                          to={`/listing/view/${data?.property?.slug}`}
                        >
                          View
                        </Link>
                      </div>
                      <div className="d-flex justify-content-between gap-5 hm-accordion-body flex-wrap">
                        <div className="hm-reservation-info">
                          <span className="fw-bold">Name </span>:{" "}
                          <span>{data?.property?.name ?? "n/a"}</span>
                        </div>
                        <div className="hm-reservation-info">
                          <span className="fw-bold">Internal Name </span>:{" "}
                          <span>{data?.property?.internal_name ?? "n/a"}</span>
                        </div>
                        <div className="hm-reservation-info">
                          <span className="fw-bold">Price </span>:{" "}
                          <span>
                            <CurrencyHelper
                              price={data?.property?.fee?.price}
                            />
                          </span>
                        </div>
                        <div className="hm-reservation-info">
                          <span className="fw-bold">Maximum Occupancy </span>:{" "}
                          <span>{data?.occupancy?.max_occupancy ?? "n/a"}</span>
                        </div>
                      </div>
                    </div>
                  </section>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          )}
        </Card.Body>
      </Card>
    </Fragment>
  );
};
export { ReservationView };
