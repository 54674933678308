import React, { Fragment } from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import { ReservationNav } from "./ReservationNav";
import { ReservationList } from "./components/ReservationList";
import { ReservationSource } from "./components/ReservationSource";
import { ReservationView } from "./components/ReservationView";
import ReservationCreate from "./components/ReservationCreate";
import NotFound from "../NotFound";

const ReservationPage = () => {
  return (
    <Fragment>
      <Routes>
        <Route
          element={
            <Fragment>
              <ReservationNav />
              <Outlet />
            </Fragment>
          }
        >
          <Route path="list" element={<ReservationList />} />
          <Route path="view/:id" element={<ReservationView />} />
          <Route path="create" element={<ReservationCreate />} />
          <Route path="source" element={<ReservationSource />} />
          <Route path="*" element={<NotFound />} />
          <Route element={<Navigate to="/reservation/list" />} />
        </Route>
      </Routes>
    </Fragment>
  );
};
export { ReservationPage };
