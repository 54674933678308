import React, { Fragment, useEffect, useState } from "react";
import Form from "react-bootstrap/esm/Form";
import { Button, Card, Col, InputGroup, Row } from "react-bootstrap";
import { axiosGet, axiosPost } from "../../../modules/helpers/RequestHelper";
import CurrencyInput from "react-currency-input-field";
import { FormButton } from "../../../modules/widgets/FormButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faClose } from "@fortawesome/free-solid-svg-icons";
import { Notify } from "../../../modules/helpers/NotificationHelper";
import { MediaCore } from "../../media/core/MediaCore";
import { useFormik } from "formik";
import { boolean, object, string, date, number, array } from "yup";
import {
  DragAndDrop,
  SectionLoader,
  useFileSelection,
} from "../../../modules/helpers/Utils";
import { clsx } from "clsx";
import { propertyArrayDataProps } from "../../../modules/models/property.interface";
import axios from "axios";

const NewProperty = () => {
  const [propertyData, setPropertyData] = useState({
    category: [],
    city: [],
    amenity: [],
  });
  const [fileList, setFileList] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isDataChanged, setIsDataChanged] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [category, city, amenity] = await Promise.all([
          axiosGet("/property/category/list"),
          axiosGet("/property/places/list"),
          axiosGet("/property/amenity/list"),
        ]);

        if (category.success) {
          setPropertyData((prevState) => ({
            ...prevState,
            category: category.categories,
          }));
        } else {
          throw new Error("An error occurred while fetching category.");
        }

        if (city.success) {
          setPropertyData((prevState) => ({
            ...prevState,
            city: city.places,
          }));
        } else {
          throw new Error("An error occurred while fetching city.");
        }

        if (amenity.success) {
          setPropertyData((prevState) => ({
            ...prevState,
            amenity: amenity.amenity,
          }));
        } else {
          throw new Error("An error occurred while fetching amenity.");
        }
      } catch (err: any) {
        setError(true);
        setErrorMessage(
          err.message || "An error occurred while fetching data.",
        );
      } finally {
        setLoading(false);
      }
    };

    fetchData();

    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      if (isDataChanged) {
        e.preventDefault();
        e.returnValue = "";
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isDataChanged]);

  const handleDataChange = () => {
    setIsDataChanged(true);
  };

  const handleSort = (sortedFileList: any[]) => {
    setFileList(sortedFileList);
  };
  const initialValues = {
    name: "",
    internal_name: "",
    description: "",
    available: "",
    availability_date: "",
    status: "",
    service_charge: "",
    caution_charge: "",
    price: "",
    address: "",
    address_two: "",
    apartment_number: "",
    place: "",
    zipcode: "",
    longitude: "",
    latitude: "",
    pet: "",
    max_occupancy: "",
    bed: "",
    bedroom: "",
    bathroom: "",
    categories: "",
    amenities: [],
    media: [],
  };

  const validationSchema = object({
    name: string().required("Property name is required."),
    internal_name: string().required("Internal name is required."),
    description: string().required("Property description is required."),
    available: number().required("Is property available?"),
    availability_date: date().required("Availability date is required."),
    status: string().required("Status is required."),
    service_charge: number().required("Service charge is required."),
    caution_charge: number().required("Caution charge is required."),
    price: number().required("Property price is required."),
    address: string().required("Address is required."),
    place: string().required("City is required."),
    zipcode: number().required("Zipcode is required."),
    longitude: number().required("Longitude is required."),
    latitude: number().required("Latitude is required."),
    pet: number().required("Pet is required."),
    max_occupancy: number().required("Max occupancy is required."),
    bed: number().required("Bed is required."),
    bedroom: number().required("Bedroom is required."),
    bathroom: number().required("Bathroom is required."),
    categories: string().required("Category is required"),
    amenities: array()
      .min(1, "Choose at least one amenity.")
      .required("Choose at least one amenity."),
    media: array().required("One or more pictures are required."),
  });

  const {
    handleSubmit,
    errors,
    touched,
    isSubmitting,
    getFieldProps,
    values,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        setSubmitting(true);

        let saveMedia;
        const fileList: any = values.media;
        const formData = new FormData();
        for (const value of fileList) {
          formData.append("pictures", value.originFileObj);
        }
        formData.append("type", "1");

        if (fileList.length > 0) {
          saveMedia = await axiosPost("/pictures/upload", formData);
        }

        if (saveMedia?.success) {
          const { images } = saveMedia;
          const amenities = values.amenities.map((item) => ({ id: item }));
          const categories = [{ id: values.categories }];

          const pictures = images.map(({ id }: any, i: number) => ({
            id,
            front: i === 0,
          }));

          const req = await axiosPost("/property/create", {
            name: values.name,
            internal_name: values.internal_name,
            description: values.description,
            available: parseInt(values.available),
            availability_date: new Date(values.availability_date),
            status: parseInt(values.status),
            fee: {
              service_charge: parseInt(values.service_charge),
              caution_charge: parseInt(values.caution_charge),
              price: parseInt(values.price),
            },
            location: {
              address: values.address,
              address_two: values.address_two,
              apartment_number: values.apartment_number,
              place: values.place,
              zipcode: values.zipcode,
              longitude: values.longitude,
              latitude: values.latitude,
            },
            occupancy: {
              pet: parseInt(values.pet),
              max_occupancy: parseInt(values.max_occupancy),
              bed: values.bed,
              bedroom: values.bedroom,
              bathroom: values.bathroom,
            },
            categories_id: categories,
            amenities_id: amenities,
            rules: [],
            pictures_id: pictures,
          });

          if (req.success) {
            resetForm();
          } else {
            for (const image of images) {
              await axios.delete(`/pictures/list/${image.id}`);
            }
            alert(req.message);
          }
        }
      } catch (e) {
        alert("An error occurred while submitting the form.");
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <Fragment>
      <Card border="light" className="bg-white shadow-sm mb-4">
        <Card.Body>
          {/* Display loading spinner if loading */}
          {loading && <SectionLoader success={false} error={false} />}

          {/* Display error message if error */}
          {error && (
            <div className="alert alert-danger" role="alert">
              {errorMessage}
            </div>
          )}

          {!loading && !error && (
            <Form className="mt-5" onSubmit={handleSubmit}>
              <div className="my-5">
                <Row className=" d-flex justify-content-between">
                  <Col md={8}>
                    <h5 className="fw-light lead text-underline">General</h5>
                    <small className="text-light-emphasis">
                      Fill in general information about property.
                    </small>
                  </Col>

                  <Col md={4}>
                    <Form.Select
                      aria-label="Property category"
                      isValid={errors.status === undefined}
                      isInvalid={errors.status !== undefined}
                      className="p-3"
                      {...getFieldProps("status")}
                      name="status"
                    >
                      <option value="">Choose Status</option>
                      <option value="1">Publish</option>
                      <option value="2">Draft</option>
                    </Form.Select>
                    {touched.status && errors.status && (
                      <Form.Control.Feedback type="invalid">
                        {errors.status}
                      </Form.Control.Feedback>
                    )}
                  </Col>
                </Row>
              </div>

              <Row>
                <Col md={4} className="mb-3">
                  <Form.Group className="mb-3">
                    <Form.Label>Property name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter property name"
                      className="p-3"
                      isValid={errors.name === undefined}
                      isInvalid={errors.name !== undefined}
                      {...getFieldProps("name")}
                      name="name"
                    />
                    {touched.name && errors.name && (
                      <Form.Control.Feedback type="invalid">
                        {errors.name}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </Col>
                <Col md={4} className="mb-3">
                  <Form.Group className="mb-3">
                    <Form.Label>Property Internal name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter property name"
                      className="p-3"
                      isValid={errors.internal_name === undefined}
                      isInvalid={errors.internal_name !== undefined}
                      {...getFieldProps("internal_name")}
                      name="internal_name"
                    />
                    {touched.internal_name && errors.internal_name && (
                      <Form.Control.Feedback type="invalid">
                        {errors.internal_name}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </Col>
                <Col md={4} className="mb-3">
                  <Form.Group>
                    <Form.Label>Property category</Form.Label>
                    <Form.Select
                      aria-label="Property category"
                      className="p-3"
                      isValid={errors.categories === undefined}
                      isInvalid={errors.categories !== undefined}
                      {...getFieldProps("categories")}
                      name="categories"
                    >
                      <option value="">Choose property category</option>
                      {propertyData.category !== undefined &&
                        propertyData.category?.length > 0 &&
                        propertyData.category.map((item: any, i: number) => (
                          <option key={i} value={item?.id}>
                            {item?.name}
                          </option>
                        ))}
                    </Form.Select>
                    {touched.categories && errors.categories && (
                      <Form.Control.Feedback type="invalid">
                        {errors.categories}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                  <small>
                    Hold down Control key + left click to select multiple
                    options
                  </small>
                </Col>
                <Col md={6} className="mb-3">
                  <Form.Group className="mb-3">
                    <Form.Label>Availability date</Form.Label>
                    <Form.Control
                      type="date"
                      placeholder="Enter property name"
                      className="p-3"
                      isValid={errors.availability_date === undefined}
                      isInvalid={errors.availability_date !== undefined}
                      {...getFieldProps("availability_date")}
                      name="availability_date"
                    />
                    {touched.availability_date && errors.availability_date && (
                      <Form.Control.Feedback type="invalid">
                        {errors.availability_date}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </Col>
                <Col md={6} className="mb-3">
                  <Form.Group>
                    <Form.Label>Available</Form.Label>
                    <Form.Select
                      aria-label="Property available"
                      className="p-3"
                      isValid={errors.available === undefined}
                      isInvalid={errors.available !== undefined}
                      {...getFieldProps("available")}
                      name="available"
                    >
                      <option>Choose Availability</option>
                      <option key={0} value="1">
                        Yes
                      </option>
                      <option key={1} value="0">
                        No
                      </option>
                    </Form.Select>
                    {touched.available && errors.available && (
                      <Form.Control.Feedback type="invalid">
                        {errors.available}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </Col>

                <Col md={12} className="mb-3">
                  <Form.Group>
                    <Form.Label>Property description</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      placeholder="Enter property name"
                      className="p-4"
                      isValid={errors.description === undefined}
                      isInvalid={errors.description !== undefined}
                      {...getFieldProps("description")}
                      name="description"
                    />
                    {touched.description && errors.description && (
                      <Form.Control.Feedback type="invalid">
                        {errors.description}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <h5 className="fw-light my-3 text-underline">Fees</h5>
              <Row>
                <Col md={4}>
                  <Form.Group className="mb-3">
                    <Form.Label>Price</Form.Label>
                    <CurrencyInput
                      prefix="&#8358;"
                      className="form-control p-3"
                      placeholder="Enter property price per night"
                      name="price"
                      value={values.price}
                      onValueChange={(value) => setFieldValue("price", value)}
                    />
                    {touched.price && errors.price && (
                      <span className="text-danger">{errors.price}</span>
                    )}
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group className="mb-3">
                    <Form.Label>Service charge</Form.Label>
                    <CurrencyInput
                      prefix="&#8358;"
                      className="form-control p-3"
                      placeholder="Enter property service charge"
                      name="service_charge"
                      value={values.service_charge}
                      onValueChange={(value) =>
                        setFieldValue("service_charge", value)
                      }
                    />
                    {touched.service_charge && errors.service_charge && (
                      <span className="text-danger">
                        {errors.service_charge}
                      </span>
                    )}
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group className="mb-3">
                    <Form.Label>Caution fee</Form.Label>
                    <CurrencyInput
                      prefix="&#8358;"
                      className="form-control p-3"
                      placeholder="Enter property caution fee"
                      name="caution_fee"
                      value={values.caution_charge}
                      onValueChange={(value) =>
                        setFieldValue("caution_charge", value)
                      }
                    />
                    {touched.caution_charge && errors.caution_charge && (
                      <span className="text-danger">
                        {errors.caution_charge}
                      </span>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <h5 className="fw-light my-3 text-underline">Occupancy</h5>
              <Row>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>Max occupancy</Form.Label>
                    <Form.Control
                      type="tel"
                      placeholder="Enter Max Occupancy"
                      className="p-3"
                      isValid={errors.max_occupancy === undefined}
                      isInvalid={errors.max_occupancy !== undefined}
                      {...getFieldProps("max_occupancy")}
                      name="max_occupancy"
                    />
                    {touched.max_occupancy && errors.max_occupancy && (
                      <Form.Control.Feedback type="invalid">
                        {errors.max_occupancy}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>Pets Allowed</Form.Label>
                    <Form.Select
                      aria-label="Pets allowed"
                      className="p-3"
                      isValid={errors.pet === undefined}
                      isInvalid={errors.pet !== undefined}
                      {...getFieldProps("pet")}
                      name="pet"
                    >
                      <option value="">Choose Pets Allowed</option>
                      <option key={0} value="1">
                        Yes
                      </option>
                      <option key={1} value="0">
                        No
                      </option>
                    </Form.Select>
                    {touched.pet && errors.pet && (
                      <Form.Control.Feedback type="invalid">
                        {errors.pet}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group className="mb-3">
                    <Form.Label>Bedroom</Form.Label>
                    <Form.Control
                      type="tel"
                      placeholder="Enter no. of bedroom"
                      className="p-3"
                      isValid={errors.bedroom === undefined}
                      isInvalid={errors.bedroom !== undefined}
                      {...getFieldProps("bedroom")}
                      name="bedroom"
                    />
                    {touched.bedroom && errors.bedroom && (
                      <Form.Control.Feedback type="invalid">
                        {errors.bedroom}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group className="mb-3">
                    <Form.Label>Bed</Form.Label>
                    <Form.Control
                      type="tel"
                      isValid={errors.bed === undefined}
                      isInvalid={errors.bed !== undefined}
                      placeholder="Enter no. of bed"
                      className="p-3"
                      {...getFieldProps("bed")}
                      name="bed"
                    />
                    {touched.bed && errors.bed && (
                      <Form.Control.Feedback type="invalid">
                        {errors.bed}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group className="mb-3">
                    <Form.Label>Bathroom</Form.Label>
                    <Form.Control
                      type="tel"
                      placeholder="Enter no. of bathroom"
                      className="p-3"
                      isValid={errors.bathroom === undefined}
                      isInvalid={errors.bathroom !== undefined}
                      {...getFieldProps("bathroom")}
                    />
                    {touched.bathroom && errors.bathroom && (
                      <Form.Control.Feedback type="invalid">
                        {errors.bathroom}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <div className="my-3">
                <h5 className="fw-light lead text-underline">Location </h5>
                <small className="text-light-emphasis">
                  Fill in property location, including address, latitude &
                  longitude for map.
                </small>
              </div>

              <Row>
                <Col md={4}>
                  <Form.Group className="mb-3">
                    <Form.Label>Address</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter address"
                      className="p-3"
                      isValid={errors.address === undefined}
                      isInvalid={errors.address !== undefined}
                      {...getFieldProps("address")}
                      name="address"
                    />
                    {touched.address && errors.address && (
                      <Form.Control.Feedback type="invalid">
                        {errors.address}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group className="mb-3">
                    <Form.Label>Address Two (Optional)</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter address two (optional)"
                      className="p-3"
                      isValid={errors.address_two === undefined}
                      isInvalid={errors.address_two !== undefined}
                      {...getFieldProps("address_two")}
                      name="address_two"
                    />
                    {touched.address_two && errors.address_two && (
                      <Form.Control.Feedback type="invalid">
                        {errors.address_two}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group>
                    <Form.Label>City</Form.Label>
                    <Form.Select
                      aria-label="location"
                      className="p-3"
                      isValid={errors.place === undefined}
                      isInvalid={errors.place !== undefined}
                      {...getFieldProps("place")}
                      name="place"
                    >
                      <option value="">Choose city</option>
                      {propertyData.city.length > 0 &&
                        propertyData.city.map((item: any) => (
                          <option value={item?.id}>
                            {item?.city}, {item?.state?.name},{" "}
                            {item?.country?.name}{" "}
                          </option>
                        ))}
                    </Form.Select>
                    {touched.place && errors.place && (
                      <Form.Control.Feedback type="invalid">
                        {errors.place}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={3}>
                  <Form.Group className="mb-3">
                    <Form.Label>Apartment no</Form.Label>
                    <Form.Control
                      type="tel"
                      placeholder="Enter apartment no."
                      className="p-3"
                      isValid={errors.apartment_number === undefined}
                      isInvalid={errors.apartment_number !== undefined}
                      {...getFieldProps("apartment_number")}
                      name="apartment_number"
                    />
                    {touched.apartment_number && errors.apartment_number && (
                      <Form.Control.Feedback type="invalid">
                        {errors.apartment_number}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group className="mb-3">
                    <Form.Label>Zip code</Form.Label>
                    <Form.Control
                      type="tel"
                      placeholder="Enter zipcode"
                      className="p-3"
                      isValid={errors.zipcode === undefined}
                      isInvalid={errors.zipcode !== undefined}
                      {...getFieldProps("zipcode")}
                      name="zipcode"
                    />
                    {touched.zipcode && errors.zipcode && (
                      <Form.Control.Feedback type="invalid">
                        {errors.zipcode}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group className="mb-3">
                    <Form.Label>Latitude</Form.Label>
                    <Form.Control
                      type="numeric"
                      placeholder="Enter latitude"
                      isValid={errors.latitude === undefined}
                      isInvalid={errors.latitude !== undefined}
                      className="p-3"
                      {...getFieldProps("latitude")}
                      name="latitude"
                    />
                    {touched.latitude && errors.latitude && (
                      <Form.Control.Feedback type="invalid">
                        {errors.latitude}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group className="mb-3">
                    <Form.Label>Longitude</Form.Label>
                    <Form.Control
                      type="numeric"
                      isValid={errors.longitude === undefined}
                      isInvalid={errors.longitude !== undefined}
                      placeholder="Enter longitude"
                      className="p-3"
                      {...getFieldProps("longitude")}
                      name="longitude"
                    />
                    {touched.longitude && errors.longitude && (
                      <Form.Control.Feedback type="invalid">
                        {errors.longitude}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <div className="row d-flex justify-content-between my-3">
                <div className="col-md-6">
                  <h5 className="fw-light text-underline">Amenities</h5>
                  <small className="text-light-emphasis">
                    Fill in general information about property.
                  </small>
                </div>
                <Col md={12}>
                  <Form.Group className="mb-3">
                    <div className="d-flex flex-wrap gap-3 my-3">
                      {propertyData.amenity?.length > 0 &&
                        propertyData.amenity
                          .sort((a: any, b: any) =>
                            a.name.localeCompare(b.name),
                          )
                          .map((item: any, i: number) => (
                            <Form.Check
                              key={i}
                              type="checkbox"
                              id={item?.id}
                              label={item?.name}
                              {...getFieldProps("amenities")}
                              name="amenities"
                              value={item?.id}
                              onChange={(e) => {
                                const { value } = e.target;
                                const nextAmenities = e.target.checked
                                  ? [...values.amenities, value]
                                  : values.amenities.filter(
                                      (id) => id !== value,
                                    );
                                setFieldValue("amenities", nextAmenities);
                              }}
                            />
                          ))}
                    </div>
                    <Form.Control.Feedback type="invalid">
                      {errors.amenities}
                    </Form.Control.Feedback>
                    ,
                  </Form.Group>
                </Col>
              </div>

              <div className="row d-flex justify-content-between align-items-center my-3">
                <div className="col-md-6">
                  <h5 className="fw-light text-underline">Media & Gallery</h5>
                  <small className="text-light-emphasis">
                    Fill in general information about property.
                  </small>
                </div>
              </div>

              <Row>
                <Col md={12}>
                  <DragAndDrop
                    onSort={handleSort}
                    setFieldValue={setFieldValue}
                    className={clsx(
                      "mb-2",
                      touched.media && errors.media ? "" : "",
                    )}
                  />
                </Col>
              </Row>

              <Row className="mt-5 d-flex justify-content-start">
                <Col md={3}>
                  <FormButton
                    type="reset"
                    text="Clear"
                    isSubmitting={false}
                    isValid={true}
                    className="w-100 p-3 bg-transparent btn-outline-secondary"
                    variant="outline-secondary"
                  />
                </Col>
                <Col md={3}>
                  <FormButton
                    type="submit"
                    text="Continue"
                    isSubmitting={isSubmitting}
                    isValid={true}
                    className="w-100 p-3"
                    variant="primary"
                  />
                </Col>
              </Row>
            </Form>
          )}
        </Card.Body>
      </Card>
    </Fragment>
  );
};
export { NewProperty };
