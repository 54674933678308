import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Card, Col, Row, Button } from "react-bootstrap";
import Select from "react-select";
import { axiosGet } from "../../../modules/helpers/RequestHelper"; // Import react-select
import CreatableSelect from "react-select/creatable";
import { DragAndDrop } from "../../../modules/helpers/Utils";
import { clsx } from "clsx";
const initialValues = {
  property: "",
  reservationSource: "",
  status: "",
  firstName: "",
  lastName: "",
  middleName: "",
  email: "",
  phone: "",
  fax: "",
  address: "",
  city: "",
  state: "",
  country: "",
  purposeOfStay: "",
  guestType: "",
  referralSource: "",
  numOfGuests: "",
  numAdults: "",
  numChildren: "",
  pet: false,
  checkIn: "",
  checkOut: "",
  extraServices: [],
};

const validationSchema = Yup.object().shape({
  property: Yup.string().required("Property is required"),
  reservationSource: Yup.string().required("Reservation source is required"),
  status: Yup.string().required("Status is required"),
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  phone: Yup.string().required("Phone is required"),
  address: Yup.string().required("Address is required"),
  city: Yup.string().required("City is required"),
  state: Yup.string().required("State is required"),
  country: Yup.string().required("Country is required"),
  purposeOfStay: Yup.string().required("Purpose of stay is required"),
  guestType: Yup.string().required("Guest type is required"),
  numOfGuests: Yup.number().required("Number of guests is required"),
  numAdults: Yup.number().required("Number of adults is required"),
  checkIn: Yup.date().required("Check-in date is required"),
  checkOut: Yup.date().required("Check-out date is required"),
});

const customStyles = {
  control: (provided: any) => ({
    ...provided,
    minHeight: "inherit", // Adjust the height to match Bootstrap's form-control
    borderColor: "inherit", // Match Bootstrap's default input border color
    borderRadius: "inherit", // Bootstrap's border-radius for inputs
    boxShadow: "inherit", // Remove box shadow for focus consistency with Bootstrap
    "&:hover": {
      borderColor: "inherit", // Change border color on hover to match Bootstrap input hover
    },
  }),
};
const ReservationForm = () => {
  const [data, setData] = useState<any>({
    property: [],
    user: [],
  });
  const handleSubmit = async (
    values: any,
    { setSubmitting, resetForm }: any,
  ) => {
    try {
      // Fake API endpoint
      const response = await fetch(
        "https://jsonplaceholder.typicode.com/posts",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(values),
        },
      );

      // Simulating a response and checking for success
      if (response.ok) {
        const data = await response.json();
        console.log("Success:", data);
        alert("Form submitted successfully!");
        resetForm(); // Optionally reset the form after successful submission
      } else {
        console.error("Failed to submit form");
        alert("Failed to submit the form. Please try again.");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred during form submission. Please try again.");
    } finally {
      setSubmitting(false); // To reset the form submission state
    }
  };

  const fetchProperty = async () => {
    const req = await axiosGet("/property/list");
    if (req?.success) {
      setData((prev: any) => ({ ...prev, property: req?.properties }));
    }
  };

  const [options, setOptions] = useState<any>([]);

  const handleCreate = (inputValue: any) => {
    const newOption = {
      value: inputValue.toLowerCase().replace(/\s+/g, "-"),
      label: inputValue,
    };
    setOptions((prevOptions: any) => [...prevOptions, newOption]);
  };
  useEffect(() => {
    fetchProperty();
  }, []);
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, values, setFieldValue }) => (
        <Form>
          <Card className="mb-4">
            <Card.Body>
              <Row className="mb-4">
                <Col md={6}>
                  <h5 className="fw-light lead text-underline">General</h5>
                  <small className="text-light-emphasis">
                    Fill in general information about reservation.
                  </small>
                </Col>
                <Col md={6}>
                  <label>Status</label>
                  <Field as="select" name="status" class="form-control">
                    <option value="">Choose Status</option>
                    <option value="0">Failed</option>
                    <option value="1">Pending</option>
                    <option value="2">Confirmed</option>
                    <option value="3">Completed</option>
                  </Field>
                  <ErrorMessage
                    name="status"
                    component="div"
                    className="text-danger"
                  />
                </Col>
              </Row>

              {/* Form fields go here, structured as described previously */}
              <Row className="mb-3">
                <Col md={6}>
                  <label>Property</label>
                  <Select
                    name="property"
                    options={data.property}
                    value={values.property}
                    onChange={(selectedOption: any) =>
                      setFieldValue("property", selectedOption)
                    }
                    classNamePrefix="react-select"
                    className="form-control p-0" // p-0 to remove padding
                    styles={customStyles}
                  />
                  <ErrorMessage
                    name="property"
                    component="div"
                    className="text-danger"
                  />
                </Col>
                <Col md={6}>
                  <label>Select Reservation Source</label>
                  <Field
                    as="select"
                    name="reservationSource"
                    className="form-control"
                  >
                    <option value="">Select Reservation Source</option>
                    {/* Populate dynamically */}
                  </Field>
                  <ErrorMessage
                    name="reservationSource"
                    component="div"
                    className="text-danger"
                  />
                </Col>
              </Row>

              <div className="mb-4">
                <h5 className="fw-light lead text-underline">Guest</h5>
                <small className="text-light-emphasis">
                  Fill in information about guest.
                </small>
              </div>
              <Row className="mb-3">
                <Col className="mb-3" md={4}>
                  <label htmlFor="email">Email</label>
                  <CreatableSelect
                    id="email"
                    name="email"
                    options={data.user}
                    value={values.email}
                    onChange={(selectedOption) =>
                      setFieldValue("email", selectedOption)
                    }
                    onCreateOption={handleCreate}
                    classNamePrefix="react-select"
                    className="form-control p-0" // Apply Bootstrap form-control styles
                    placeholder="Search or create..."
                    isClearable
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        minHeight: "38px",
                        borderColor: "#ced4da",
                        borderRadius: "0.375rem",
                        boxShadow: "none",
                      }),
                    }}
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="text-danger"
                  />
                </Col>
                <Col className="mb-3" md={4}>
                  <label htmlFor="phone">Phone</label>
                  <CreatableSelect
                    id="phone"
                    name="phone"
                    options={options}
                    value={values.phone}
                    onChange={(selectedOption) =>
                      setFieldValue("phone", selectedOption)
                    }
                    onCreateOption={handleCreate}
                    classNamePrefix="react-select"
                    className="form-control p-0" // Apply Bootstrap form-control styles
                    placeholder="Search or create..."
                    isClearable
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        minHeight: "38px",
                        borderColor: "#ced4da",
                        borderRadius: "0.375rem",
                        boxShadow: "none",
                      }),
                    }}
                  />
                  <ErrorMessage
                    name="phone"
                    component="div"
                    className="text-danger"
                  />
                </Col>
                <Col className="mb-3" md={4}>
                  <label>Fax</label>
                  <Field
                    as="input"
                    name="fax"
                    class="form-control"
                    placeholder="Enter fax"
                  />
                  <ErrorMessage
                    name="fax"
                    component="div"
                    className="text-danger"
                  />
                </Col>
                <Col className="mb-3" md={4}>
                  <label>First Name</label>
                  <Field
                    as="input"
                    name="firstname"
                    class="form-control"
                    placeholder="Enter fax"
                  />
                  <ErrorMessage
                    name="firstname"
                    component="div"
                    className="text-danger"
                  />
                </Col>
                <Col className="mb-3" md={4}>
                  <label>Last Name</label>
                  <Field
                    as="input"
                    name="lastname"
                    class="form-control"
                    placeholder="Enter fax"
                  />
                  <ErrorMessage
                    name="lastname"
                    component="div"
                    className="text-danger"
                  />
                </Col>
                <Col className="mb-3" md={4}>
                  <label>Middle Name</label>
                  <Field
                    as="input"
                    name="middlename"
                    class="form-control"
                    placeholder="Enter fax"
                  />
                  <ErrorMessage
                    name="middlename"
                    component="div"
                    className="text-danger"
                  />
                </Col>
              </Row>

              <Row className="mb-3">
                <Col md={12}>
                  <label>Government Issued ID</label>
                  <DragAndDrop
                    onSort={() => {}}
                    onChange={(selectedOption) =>
                      setFieldValue("government_id", selectedOption)
                    }
                    setFieldValue={""}
                  />
                </Col>
              </Row>

              <Row className="mb-3">
                <Col className="mb-3" md={4}>
                  <label>Address</label>
                  <Field
                    as="input"
                    name="address"
                    class="form-control"
                    placeholder="Enter Address"
                  />
                  <ErrorMessage
                    name="address"
                    component="div"
                    className="text-danger"
                  />
                </Col>
                <Col className="mb-3" md={4}>
                  <label htmlFor="city">City</label>
                  <Field
                    as="input"
                    name="city"
                    class="form-control"
                    placeholder="Enter City"
                  />
                  <ErrorMessage
                    name="city"
                    component="div"
                    className="text-danger"
                  />
                </Col>

                <Col className="mb-3" md={4}>
                  <label>State</label>
                  <Field
                    as="input"
                    name="neighborhood"
                    class="form-control"
                    placeholder="Enter fax"
                  />
                  <ErrorMessage
                    name="firstname"
                    component="div"
                    className="text-danger"
                  />
                </Col>
                <Col className="mb-3" md={12}>
                  <label>Country</label>
                  <Field name="country" as="select" className="form-control">
                    <option selected disabled>
                      Select Country
                    </option>
                  </Field>
                  <ErrorMessage
                    name="country"
                    component="div"
                    className="text-danger"
                  />
                </Col>
              </Row>

              <Row className="mt-4">
                <Col md={3}>
                  <Button
                    type="reset"
                    variant="outline-secondary"
                    className="w-100"
                  >
                    Clear
                  </Button>
                </Col>
                <Col md={3}>
                  <Button
                    type="submit"
                    variant="primary"
                    className="w-100"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? "Submitting..." : "Continue"}
                  </Button>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Form>
      )}
    </Formik>
  );
};

export default ReservationForm;
