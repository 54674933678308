import React, { Fragment, useEffect, useState } from "react";
import { Table } from "../../../../modules/widgets/table/Table";
import { axiosGet } from "../../../../modules/helpers/RequestHelper";
import {
  PlacesColumn,
  PropertyCategoryColumn,
} from "../../../../modules/widgets/table/core/Columns";
import { Button } from "react-bootstrap";
import { LocationModal } from "../../../../modules/widgets/modals/location/LocationModal";
import { SectionLoader } from "../../../../modules/helpers/Utils";
import Search from "antd/lib/input/Search";

const LocationList = () => {
  const [data, setData] = useState<any[]>([]);
  const [openCreateCatModal, setOpenCreateCatModal] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const req = await axiosGet("/property/places/list");
        if (req?.success) {
          const { places } = req;
          setData(places);
        }
      } catch (err) {
      } finally {
      }
    };

    fetchData();
  }, []);

  return (
    <Fragment>
      <div className="d-flex gap-4">
        <LocationModal
          show={openCreateCatModal}
          onHide={() => setOpenCreateCatModal(false)}
        />
      </div>

      <div className="d-flex justify-content-end my-3">
        <Button onClick={() => setOpenCreateCatModal(true)} variant="primary">
          Create Location
        </Button>
      </div>

      {/* Display content if not loading and no error */}
      <Table columns={PlacesColumn} data={data} />
    </Fragment>
  );
};

export { LocationList };
