import React, { FC, Fragment } from "react";
import { boolean, object, string } from "yup";
import { useFormik } from "formik";
import { axiosPost } from "../../../helpers/RequestHelper";
import { CloseButton, Col, Modal, ModalProps, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { FormButton } from "../../FormButton";

const CreateAmenityModal: FC<ModalProps> = ({ show, onHide }) => {
  const initialValues = {
    name: "",
    icon: "",
    description: "",
  };
  const validationSchema = object({
    name: string().required("Amenity name is required."),
    icon: string(),
    description: string().required("Amenity description is required."),
  });
  const {
    getFieldProps,
    isSubmitting,
    touched,
    errors,
    handleSubmit,
    isValid,
    values,
  } = useFormik({
    initialValues,
    // isInitialValid: true,
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setSubmitting(true);
      try {
        const req = await axiosPost("/property/amenity/create", {
          name: values.name,
          slug: values.icon,
          description: values.description,
        });
        if (req?.success) {
          resetForm();
        }
      } catch (e) {
      } finally {
        setSubmitting(false);
      }
    },
  });
  return (
    <Fragment>
      <Modal size="lg" centered show={show} onHide={onHide}>
        <Modal.Header className="d-flex align-baseline">
          <h4>Create Amenity - {values?.name}</h4> <CloseButton />
        </Modal.Header>
        <Modal.Body className="p-3">
          <form onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Col md={6}>
                <Form.Group>
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    isInvalid={!!errors.name}
                    placeholder="Enter name"
                    {...getFieldProps("name")}
                    name="name"
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.name}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Label>
                    Icon{" "}
                    <small className="text-danger">
                      (User either remix icon or line awesome classname)
                    </small>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    isInvalid={!!errors.icon}
                    placeholder="Enter icon classname. Ex: las la-car"
                    {...getFieldProps("icon")}
                    name="icon"
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.icon}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col md={12}>
                <Form.Group>
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    as="textarea"
                    {...getFieldProps("description")}
                    name="description"
                    isInvalid={!!errors.description}
                    rows={6}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.description}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col md={4}>
                <FormButton
                  type="submit"
                  isSubmitting={isSubmitting}
                  isValid={isValid}
                  text="Save Changes"
                />
              </Col>
            </Row>
          </form>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};
export { CreateAmenityModal };
