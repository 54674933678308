import { FC, Fragment, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import { EditPropertyCategoryModal } from "../../modals/category/EditPropertyCategoryModal";
import { axiosDelete } from "../../../helpers/RequestHelper";
import { EditAmenityModal } from "../../modals/amenity/EditAmenityModal";

import { ReservationSourceModal } from "../../modals/reservation/ReservationSourceModal";
import { LocationModal } from "../../modals/location/LocationModal";
import { Link } from "react-router-dom";

const ReservationColumnAction: FC<any> = ({ slug }) => {
  return (
    <Fragment>
      <Dropdown style={{ fontSize: "12px" }}>
        <Dropdown.Toggle className="bg-transparent border-0 text-black">
          <FontAwesomeIcon icon={faEllipsisH} />
        </Dropdown.Toggle>

        <Dropdown.Menu style={{ fontSize: "12px" }}>
          <Dropdown.Item as={Link} to={`/reservation/view/${slug}`}>
            View
          </Dropdown.Item>
          <Dropdown.Item as={Link} to={`/reservation/edit/${slug}`}>
            Edit
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </Fragment>
  );
};

const PropertyCategoryColumnAction: FC<any> = ({ data }) => {
  const [openEditModal, setOpenEditModal] = useState(false);
  const handleDelete = async () => {
    const request = await axiosDelete(`/property/category/list/${data?.id}`);
  };
  return (
    <Fragment>
      <EditPropertyCategoryModal
        show={openEditModal}
        onHide={() => setOpenEditModal(false)}
        data={data}
      />
      <Dropdown style={{ fontSize: "12px" }}>
        <Dropdown.Toggle className="bg-transparent border-0 text-black">
          <FontAwesomeIcon icon={faEllipsisH} />
        </Dropdown.Toggle>

        <Dropdown.Menu style={{ fontSize: "12px" }}>
          <Dropdown.Item onClick={() => setOpenEditModal(true)}>
            Edit
          </Dropdown.Item>
          <Dropdown.Item onClick={handleDelete}>Delete</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </Fragment>
  );
};
const AmenityColumnAction: FC<any> = ({ data }) => {
  const [openEditModal, setOpenEditModal] = useState(false);
  const handleDelete = async () => {
    const request = await axiosDelete(`/property/amenity/list/${data?.id}`);
  };
  return (
    <Fragment>
      <EditAmenityModal
        show={openEditModal}
        onHide={() => setOpenEditModal(false)}
        data={data}
      />
      <Dropdown style={{ fontSize: "12px" }}>
        <Dropdown.Toggle className="bg-transparent border-0 text-black">
          <FontAwesomeIcon icon={faEllipsisH} />
        </Dropdown.Toggle>

        <Dropdown.Menu style={{ fontSize: "12px" }}>
          <Dropdown.Item onClick={() => setOpenEditModal(true)}>
            Edit
          </Dropdown.Item>
          <Dropdown.Item onClick={handleDelete}>Delete</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </Fragment>
  );
};
const PlacesColumnAction: FC<any> = ({ data }) => {
  const [openEditModal, setOpenEditModal] = useState(false);
  const handleDelete = async () => {
    const request = await axiosDelete(`/property/places/list/${data?.id}`);
  };
  return (
    <Fragment>
      <LocationModal
        show={openEditModal}
        onHide={() => setOpenEditModal(false)}
        data={data}
      />
      <Dropdown style={{ fontSize: "12px" }}>
        <Dropdown.Toggle className="bg-transparent border-0 text-black">
          <FontAwesomeIcon icon={faEllipsisH} />
        </Dropdown.Toggle>

        <Dropdown.Menu style={{ fontSize: "12px" }}>
          <Dropdown.Item onClick={() => setOpenEditModal(true)}>
            Edit
          </Dropdown.Item>
          <Dropdown.Item onClick={handleDelete}>Delete</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </Fragment>
  );
};
const SourceColumnAction: FC<any> = ({ data }) => {
  const [openEditModal, setOpenEditModal] = useState<any>(false);
  const handleDelete = async () => {
    const request = await axiosDelete(`/reservation/source/list/${data?.id}`);
  };
  return (
    <Fragment>
      <ReservationSourceModal
        show={openEditModal}
        onHide={() => setOpenEditModal(false)}
        data={data}
      />
      <Dropdown
        drop="up"
        style={{
          fontSize: "12px",
          position: "absolute",
          inset: "auto auto 0px 0px",
          transform: "translate3d(-123px, 4px, 0px)",
        }}
      >
        <Dropdown.Toggle className="bg-transparent border-0 text-black">
          <FontAwesomeIcon icon={faEllipsisH} />
        </Dropdown.Toggle>

        <Dropdown.Menu style={{ fontSize: "12px" }}>
          <Dropdown.Item onClick={() => setOpenEditModal(true)}>
            Edit
          </Dropdown.Item>
          <Dropdown.Item onClick={handleDelete}>Delete</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </Fragment>
  );
};
export {
  ReservationColumnAction,
  PropertyCategoryColumnAction,
  AmenityColumnAction,
  PlacesColumnAction,
  SourceColumnAction,
};
