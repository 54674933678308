import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {toast} from "react-toastify"
import {FC} from "react";


export const MySwal = withReactContent(Swal)
type sweetAlertProp  = {
    title: any
    icon: "success" | "error" | "warning" | any
    text: any
}
export const sweetAlert = ({title, icon, text}:sweetAlertProp)=> {

    return MySwal.fire({
        title: `<strong>${title}</strong>`,
        text: text,
        icon: icon,
        iconColor: `#BD9241`,
        confirmButtonText: `Continue`,
        confirmButtonColor: `#010038`
    })
}


type toastrProps = {
    text: any
    type: "success" | "error" | "warning" | any
}
export const toastr = ({text, type}: toastrProps)=> {
    return toast(text, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        type: type
    });
}

type notifyProps = {
    type: "toastr" | "sweetalert"
    sweetAlertProps?: sweetAlertProp
    toastrProps?: toastrProps
}
export const Notify =({type, sweetAlertProps, toastrProps}:notifyProps)=>{
    try {

        switch (type) {
            case `sweetalert`:
                sweetAlert({title: sweetAlertProps?.title, text: sweetAlertProps?.text, icon: sweetAlertProps?.icon})
                break;

            case`toastr`:
                toastr({text: toastrProps?.text,  type: toastrProps?.type})
                break

            default:
                toastr({text: toastrProps?.text, type: toastrProps?.type})
                break
        }

    }catch (error){

    }
}

