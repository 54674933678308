import { Fragment, useEffect, useState } from "react";
import { Table } from "../../../../modules/widgets/table/Table";
import { axiosGet } from "../../../../modules/helpers/RequestHelper";
import { PropertyCategoryColumn } from "../../../../modules/widgets/table/core/Columns";
import { Button } from "react-bootstrap";
import { CreatePropertyCategoryModal } from "../../../../modules/widgets/modals/category/CreatePropertyCategoryModal";

const CategoryList = () => {
  const [data, setData] = useState<any[]>([]);
  const [openCreateCatModal, setOpenCreateCatModal] = useState(false);
  useEffect(() => {
    (async () => {
      const req = await axiosGet("property/category/list");
      if (req?.success) {
        console.log(req);
        const { categories } = req;
        setData(categories);
      }
    })();
  }, []);

  return (
    <Fragment>
      <CreatePropertyCategoryModal
        show={openCreateCatModal}
        onHide={() => setOpenCreateCatModal(false)}
      />
      <div className="d-flex justify-content-end my-3">
        <Button onClick={() => setOpenCreateCatModal(true)} variant="primary">
          Create Category
        </Button>
      </div>
      <Table columns={PropertyCategoryColumn} data={data} />
    </Fragment>
  );
};
export { CategoryList };
