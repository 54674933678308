import { Fragment } from "react";
import { Col, Nav, Row, Tab } from "react-bootstrap";
import { Link } from "react-router-dom";
import { PageTabItem } from "../../modules/layout/menu/PageTabItem";

const PropertyNav = () => {
  return (
    <Fragment>
      <div className="d-flex justify-content-start align-content-start text-start mb-4 border-bottom p-0">
        <Nav
          variant="pills"
          className="d-flex justify-content-start align-content-start text-start   "
          defaultActiveKey="/listing/all"
        >
          <PageTabItem to="/listing/all" title="All" />
          <PageTabItem to="/listing/new" title="Create" />
          <PageTabItem to="/listing/category" title="Category" />
          <PageTabItem to="/listing/amenity" title="Amenity" />
          <PageTabItem to="/listing/places" title="Location" />
        </Nav>
      </div>
    </Fragment>
  );
};
export { PropertyNav };
